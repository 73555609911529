// use this class to wrap the Amplify API calls
// (which is really just a wrapper for axios: https://axios-http.com/)\
import { Auth } from "@aws-amplify/auth"
import {
  ActivityStatus,
  ActivityVisibility,
  CampaignModelI,
  ConfirmRegistrationsModel,
  DonationDisbursementBatchResponseModelI,
  DonationDisbursementBatchStatus,
  DonationDisbursementBatchUpdateModelI,
  DonationDisbursementNonProfitOrgBatchResponseModelI,
  DonationInvoiceBatchModelResponse,
  DonationInvoiceBatchStatus,
  DonationMatchPolicyStatus,
  DonationStatus,
  FieldTripPlanCreate,
  FieldTripPlanListResponse,
  FieldTripPlanResponse,
  FieldTripPlanState,
  FieldTripPlanUpdate,
  FileModelResponse,
  ImageModelResponse,
  ImageState,
  ManagedFieldTripModelI,
  MatchApprovalStatus,
  MatchableDonationModelI,
  MatchableDonationModelResponse,
  MatchableDonationUpdateModelI,
  MembershipMetadata,
  MessageType,
  MetroRegion,
  NonProfitOrgInternalConfig,
  OrgInvitationModelI,
  OrgInvitationModelResponse,
  OrgStatus,
  OrgUnitConfig,
  OrgUnitInternalConfig,
  PlatformDonationModelI,
  PlatformDonationModelResponse,
  RegistrationModelI,
  RegistrationResponse,
  RegistrationStatus,
  ReviewVolunteerEventReportModelI,
  ScheduledActivityOwner,
  ScheduledActivitySharing,
  SurveyAnswerModelBase,
  UserRole,
  VolunteerEventReportModelI
} from '@fieldday/fielddayportal-model'
import { FieldTripPlanReminderState, UserOrgType } from '@fieldday/fielddayportal-model/dist/src/model/FieldTripPlan'
import { RegistrationConfirmationStatus, RegistrationContactStatus } from '@fieldday/fielddayportal-model/dist/src/model/RegistrationStatus'
import { DonationInvoiceBatchUpdateModel } from '@fieldday/fielddayportal-model/dist/src/model/schemas/DonationInvoiceBatch'
import { FieldTripPlanCreateStub } from "@fieldday/fielddayportal-model/dist/src/model/schemas/FieldTripPlanSchema"
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import dayjs, { Dayjs } from 'dayjs'
import qs from "qs"
import { useHistory } from 'react-router'
import { ActivityQueryParams } from '../components/Activities/ActivityFilterMenu'
import { AUTH_ENDPOINT, AWS_AUTH_CONFIG, ENDPOINT } from '../config/aws-auth'
import { useErrorMessages } from '../hooks/errorMessagesContext'
import { bearerTokenKey, cognitoNoUserError, userInfoKey } from '../hooks/useAuth'
import { AlertSeverity, useLoading } from '../hooks/useLoading'
import { UploadData } from '../hooks/useProcessImage'
import {
  Activity,
  Location,
  ScheduledActivity,
  ScheduledActivityForView,
  SurveyAnswer,
  SurveyQuestion,
} from '../models/Activity'
import { Campaign, CampaignParticipant } from '../models/Campaign'
import { DonationMatchPolicy, DonationPolicyStats, NpoDonationAggregates } from '../models/Donations'
import { GroupMember } from '../models/GroupMember'
import { NPO, NPOWithActivities } from '../models/Npo'
import { Employer, OrgUnit } from '../models/OrgUnit'
import { Registration } from '../models/Registration'
import { ScheduledActivityImage } from '../models/ScheduledActivityImage'
import { ScheduledActivityMessage } from '../models/ScheduledActivityMessage'
import { MembershipWithUser, UserBase, UserInfo } from '../models/UserInfo'
import { guessedTimeZone, objFromTzAsUtc, objFromUtcAsTz, tsFormatDate } from './dateUtil'
import { RecursivePartial, removeUndefinedProperties } from './objectUtil'
import { sleep } from './sleep'

const APIDATEFORMAT = "YYYY-MM-DD"

// used for API paths
export type OrgType = "nonprofit" | "orgunit";
type customErrors = (status?: number, data?: Record<string, any>) => string | undefined

// Helper type to use when passing a FieldDayAPI object
// to a function that is not a component since useAPI
// cannot be called outside of a react component.
export type FieldDayAPIProvider = ReturnType<typeof useAPI>

export const useAPI = () => {
  const history = useHistory()
  const { setAlert } = useLoading()
  const { setInfoMessage } = useErrorMessages()

  // by default, the response type is "any" but you can specify an interface as needed
  async function withErrorHandling<T = AxiosResponse<any, any>>(
    promise: Promise<T>,
    returnValueOnAbort?: T,
    customErrors?: customErrors,
    customErrorRedirect?: string,
  ): Promise<T> {
    // NOTE: this needs to be awaited, otherwise the catching of the error in the promise doesn't
    // stop the unhandled exception. See the last section on this doc:
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/async_function#description
    const resp = await promise.catch(err => {
      if (axios.isCancel(err)) {
        // canceled due to either component unmount or useEffect lifecycle, so return default value if possible
        return returnValueOnAbort
      }

      if (err.response?.data?.message === "Insufficient profile details") {
        const redirectTo = customErrorRedirect ? customErrorRedirect : history.location
        setAlert(AlertSeverity.ERROR, "Complete your profile to continue")
        history.push('/profile/edit', { from: redirectTo })
        return {} as T // getting redirected so this doesn't matter
      }

      // assume custom errors always needs a redirect
      if (customErrors) {
        const customErrorMessage = customErrors(err.response?.status ?? err.status, err.response?.data)
        if (customErrorMessage) {
          console.log(customErrorMessage)
          setInfoMessage(customErrorMessage)
          history.push('/', err.status === 401 ? { from: history.location } : {})
        }
      }

      if (err === cognitoNoUserError) {
        // clear the state by signing out fully
        history.push("/signout")
      }
    })
    if (resp) return resp
    // if you're here, then you can fix this by passing a default return value
    console.warn("Empty response after error handling.")
    return {} as T
  }

  /***
   * Wrapper for the aws amplify RestApi so we can precheck for the session
   * if this is an authenticated API call
   */
  class RestApi {
    static async setBearerToken() {
      try {
        // early test for session before attempting the header construction
        const token = `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        localStorage.setItem(bearerTokenKey, token)
        return token
      } catch (err) {
        if (err === cognitoNoUserError) {
          localStorage.removeItem(bearerTokenKey)
          const userFromLocal = localStorage.getItem(userInfoKey)
          if (userFromLocal) {
            setAlert(AlertSeverity.WARNING, "Session expired, please sign in again.")
          } else {
            setAlert(AlertSeverity.INFO, "Please sign in to continue.", 30_000)
          }
          return Promise.reject(cognitoNoUserError)
        }
      }
    }

    static async apiWithRetries(promiseFn: () => Promise<any>): Promise<any> {
      const prom = async (retryNum: number): Promise<any> => {
        return promiseFn()
          .catch(async err => {
            // thanks cognito for that one error that's just a string
            if (err === cognitoNoUserError) return Promise.reject(err)
            // this could be just a normal cancel via an abortcontroller
            if (axios.isCancel(err)) {
              console.log("canceled request in retry", err)
              return Promise.reject(err)
            }

            const status = err.response?.status
            // empty err response is probably "network error"
            // 429 = throttle from API Gateway
            // 503 = throttle from Lambda
            if ((!status || status === 429 || status === 503) && retryNum > 0) {
              console.log(`${err.message} (${status ?? "no status"}); retrying (${retryNum} tries remaining)`)
              // retryNum starts at 20 and counts down, so retry after:
              // 100ms, 200ms, 300ms, etc
              await sleep(2100 - retryNum * 100)
              return prom(retryNum - 1)
            }
            return Promise.reject(err)
          })
      }
      return prom(20)
    }

    static async get(apiName: string, path: string, init: { queryStringParameters?: object }): Promise<any> {
     return this.apiWithRetries(() => this.withErrorDetails(path, "GET", this.getPlain(apiName, path, init)))
    }
    static async put(apiName: string, path: string, init: { body?: object, queryStringParameters?: object }): Promise<any> {
      return this.apiWithRetries(() => this.withErrorDetails(path, "PUT", this.putPlain(apiName, path, init)))
    }
    static async post(apiName: string, path: string, init: { body?: object | string, queryStringParameters?: object }): Promise<any> {
      return this.apiWithRetries(() => this.withErrorDetails(path, "POST", this.postPlain(apiName, path, init)))
    }
    static async del(apiName: string, path: string, init: { queryStringParameters: object }): Promise<any> {
      return this.apiWithRetries(() => this.withErrorDetails(path, "DELETE", this.delPlain(apiName, path, init)))
    }

    static async axiosGet(endpointName: string, path: string, axiosConfig: AxiosRequestConfig = {}): Promise<AxiosResponse> {
      const pathWithQuery = path + (axiosConfig.params ? "?" + qs.stringify(axiosConfig.params) : "")
      return this.apiWithRetries(() => this.withErrorDetails(pathWithQuery, "GET", this.axiosGetPlain(endpointName, path, axiosConfig)))
    }

    static async axiosPost(endpointName: string, path: string, body?: object | string, axiosConfig?: AxiosRequestConfig): Promise<AxiosResponse> {
      return this.apiWithRetries(() => this.withErrorDetails(path, "POST", this.axiosPostPlain(endpointName, path, body, axiosConfig)))
    }

    static async axiosPut(endpointName: string, path: string, body?: object, axiosConfig?: AxiosRequestConfig): Promise<AxiosResponse> {
      return this.apiWithRetries(() => this.withErrorDetails(path, "PUT", this.axiosPutPlain(endpointName, path, body, axiosConfig)))
    }

    static async axiosDelete(endpointName: string, path: string, axiosConfig: AxiosRequestConfig = {}): Promise<AxiosResponse> {
      return this.apiWithRetries(() => this.withErrorDetails(path, "DELETE", this.axiosDeletePlain(endpointName, path, axiosConfig)))
    }

    static async getPlain(apiName: string, path: string, init: { queryStringParameters?: object }): Promise<any> {
      const resp = await RestApi.axiosGet(apiName, path, { params: init.queryStringParameters })
      return resp.data
    }

    private static async putPlain(apiName: string, path: string, init: { body?: object, queryStringParameters?: object }): Promise<any> {
      const resp = await RestApi.axiosPut(apiName, path, init.body, { params: init.queryStringParameters })
      return resp.data
    }

    private static async postPlain(apiName: string, path: string, init: { body?: object | string, queryStringParameters?: object }): Promise<any> {
      const resp = await RestApi.axiosPost(apiName, path, init.body, { params: init.queryStringParameters })
      return resp.data
    }

    private static async delPlain(apiName: string, path: string, init: { queryStringParameters: object }): Promise<any> {
      const resp = await RestApi.axiosDelete(apiName, path, { params: init.queryStringParameters })
      return resp.data
    }

    // note: this one is used by GET /notifications/count where throttles can be safely ignored
    static async axiosGetNotifications(endpointName: string, path: string, axiosConfig: AxiosRequestConfig = {}) {
      const resp = await this.axiosGetPlain(endpointName, path, axiosConfig)
      return resp.data
    }

    private static async axiosGetPlain(endpointName: string, path: string, axiosConfig: AxiosRequestConfig = {}): Promise<AxiosResponse> {
      const authToken = endpointName === AUTH_ENDPOINT ? await this.setBearerToken() : undefined
      if (authToken) axiosConfig.headers = { Authorization: `Bearer ${authToken}` }

      const client = await buildAxiosClient(endpointName)
      const configWithParams = {
        paramsSerializer: function(params: Record<string, any>) {
          return qs.stringify(params, { arrayFormat: 'repeat' })
        },
        ...axiosConfig,
      }
      return client.get(path, configWithParams)
    }

    private static async axiosPostPlain(
      endpointName: string, path: string, body?: object | string, axiosConfig?: AxiosRequestConfig,
    ): Promise<AxiosResponse> {
      if (endpointName === AUTH_ENDPOINT) await this.setBearerToken()

      const client = await buildAxiosClient(endpointName)
      return client.post(path, body, axiosConfig)
    }

    private static async axiosPutPlain(endpointName: string, path: string, body?: object, axiosConfig?: AxiosRequestConfig): Promise<AxiosResponse> {
      if (endpointName === AUTH_ENDPOINT) await this.setBearerToken()

      const client = await buildAxiosClient(endpointName)
      return client.put(path, body, axiosConfig)
    }

    private static async axiosDeletePlain(endpointName: string, path: string, axiosConfig: AxiosRequestConfig = {}): Promise<AxiosResponse> {
      if (endpointName === AUTH_ENDPOINT) await this.setBearerToken()

      const client = await buildAxiosClient(endpointName)
      return client.delete(path, axiosConfig)
    }

    private static async withErrorDetails(path: any, method: string, promise: Promise<any>): Promise<any> {
      return promise.catch(e => {
        if (axios.isCancel(e)) return Promise.reject(e) // don't log on cancels
        console.log({
          msg: "Rethrowing error from API request",
          path: path,
          method: method,
          errorMessage: e.message,
          errorResp: e.response,
          e: e,
        })
        // Reject the error to retain the behavior of withErrorHandling
        return Promise.reject(e)
      })
    }
  }

  return class FieldDayAPI {
    /*****************/
    /* WIZARD ✨✨✨ */
    /*****************/
    public static async getMetricsCsv(bin: "month" | "week" = "month", year: string) {
      return withErrorHandling(RestApi.get(AUTH_ENDPOINT, "/wizard/metrics", { queryStringParameters: { bin: bin, year: year }}))
    }

    public static async updateNonprofitStatus(npoId: string, status: OrgStatus) {
      return withErrorHandling(RestApi.put(AUTH_ENDPOINT, "/wizard/updateOrgStatus", {
        body: { orgId: npoId, orgType: UserOrgType.Nonprofit, status: status }
      }))
    }

    public static async updateOrgUnitStatus(orgUnitId: string, status: OrgStatus) {
      return withErrorHandling(RestApi.put(AUTH_ENDPOINT, "/wizard/updateOrgStatus", {
        body: { orgId: orgUnitId, orgType: UserOrgType.OrgUnit, status: status }
      }))
    }

    public static async wizardListSystemEvents({
      page, perPage, actionType, resourceType
    }: {
      page: number,
      perPage: number,
      actionType?: string,
      resourceType?: string,
    }) {
      return withErrorHandling(RestApi.get(AUTH_ENDPOINT, "/wizard/systemEvents", { queryStringParameters: {
        page, perPage, actionType, resourceType
      }}))
    }

    public static async wizardListSurveys({
      page, perPage, questionId
    }: {
      page: number,
      perPage: number,
      questionId?: string,
    }) {
      return withErrorHandling(RestApi.get(AUTH_ENDPOINT, "/wizard/surveys", { queryStringParameters: {
        page, perPage, questionId: questionId
      }}))
    }

    public static async getDashMetrics(orgType: OrgType, resourceId: string, startDate: string, endDate?: string) {
      return withErrorHandling(RestApi.get(AUTH_ENDPOINT, `/${orgType}/${resourceId}/metrics`, { queryStringParameters: {
        startDate: startDate, endDate: endDate
      }}))
    }

    /****************/
    /* USER PROFILE */
    /****************/

    // get the logged-in user info (name, etc)
    public static async getUserProfile(): Promise<UserInfo> {
      const resp = await withErrorHandling<AxiosResponse<UserInfo>>(RestApi.axiosGet(AUTH_ENDPOINT, "/profile", {}))
      const etag = resp.headers.etag
      const userInfo: UserInfo = resp.data
      userInfo.etag = etag
      return userInfo
    }

    // update logged-in user info
    public static updateUserProfile(user: UserBase) {
      return withErrorHandling(RestApi.put(AUTH_ENDPOINT, "/profile", { body: user }))
    }

    /****************/
    /* IMAGE UPLOAD */
    /****************/

    // get presigned s3 upload url
    // optional parameters type (npo | org) and id to get an upload url associated with a non user entity
    public static getUploadUrl(type?: OrgType, id?: string, noExtension?: boolean) {
      const params: { noExtension?: "true" } = {}
      if (noExtension) params.noExtension = "true"
      const path = type && id ? `/start_upload/${type}/${id}` : "/start_upload";
      return withErrorHandling<UploadData>(RestApi.get(AUTH_ENDPOINT, path, { queryStringParameters: params }))
    }

    public static startUpload(saId: string, count: number, orgUnitId?: string | null): Promise<ImageModelResponse[]> {
      const queryStringParameters: { count: number, orgUnitId?: string | null } = { count: count }
      if (orgUnitId) { queryStringParameters.orgUnitId = orgUnitId }
      return RestApi.get(AUTH_ENDPOINT, `/startUpload/scheduledActivity/${saId}`, { queryStringParameters: queryStringParameters })
    }

    public static updateImage(imageId: string, newState: ImageState, fileName?: string) {
      return RestApi.put(AUTH_ENDPOINT, `/updateImage/${imageId}`, { body: {
        state: newState,
        fileName: fileName,
      }})
    }

    /*****************/
    /*   NONPROFITS  */
    /*****************/

    // get nonprofit by handle (not authenticated)
    public static getNpo(handle:string) {
      return withErrorHandling(RestApi.get(ENDPOINT, `/nonprofit/${handle}`, {}))
    }

    // get nonprofit with user role (owner, coordinator, member, etc) (authenticated user)
    public static getNpoWithAuth(handle:string) {
      return withErrorHandling(RestApi.get(AUTH_ENDPOINT, `/nonprofit/${handle}`, {}))
    }

    public static getNpoById(id: string) {
      return withErrorHandling(RestApi.get(ENDPOINT, `/nonprofit/id/${id}`, {}))
    }

    public static getNpoByEin(ein: string) {
      return withErrorHandling(RestApi.get(ENDPOINT, `/nonprofit/ein/${ein}`, {}))
    }

    public static getNpoByIdWithAuth(id: string) {
      return withErrorHandling(RestApi.get(AUTH_ENDPOINT, `/nonprofit/id/${id}`, {}))
    }

    public static createNpo(npo:NPO) {
      const { id, ...npoRequest } = npo
      return withErrorHandling(RestApi.post(AUTH_ENDPOINT, "/nonprofit", { body: npoRequest }))
    }

    public static updateNpo(npoId: string, updatedNpo: NPO) {
      return withErrorHandling(RestApi.put(AUTH_ENDPOINT, `/nonprofit/${npoId}`, { body: updatedNpo }))
    }

    public static updateNpoInternalConfig(id: string, nonProfitOrgInternalConfig: NonProfitOrgInternalConfig) {
      return withErrorHandling<{
        nonProfitOrg: NPO,
      }>(RestApi.put(AUTH_ENDPOINT, `/wizard/nonprofit/${id}/internalconfig`, { body: nonProfitOrgInternalConfig }))
    }

    public static listNpos(params:{
      causes?: string[],
      namelike?: string,
      region?: MetroRegion,
      matchPolicyId?: string,
      status?: string[],
    },
      controller?: AbortController,
    ): Promise<AxiosResponse<{ nonProfitOrgs: NPOWithActivities[], total: number }>> {
      return withErrorHandling(RestApi.axiosGet(ENDPOINT, `/nonprofits`, {
        signal: controller?.signal,
        params: { ...params, perPage: 500 }
      }), { data: { nonProfitOrgs: [], total: 0 }, status: 200 } as AxiosResponse)
    }

    public static listNposWithAuth(namelike?: string, statuses: OrgStatus[] = []) {
      return withErrorHandling<{ nonProfitOrgs: NPOWithActivities[] }>(RestApi.get(AUTH_ENDPOINT, `/nonprofits`, {
        queryStringParameters: { status: statuses, namelike: namelike }
      }))
    }

    public static listNposForConfig(params:{
      status?: string[],
      withDisbursementDetails?: boolean
    },
      controller?: AbortController,
    ): Promise<AxiosResponse<{ nonProfitOrgs: NPOWithActivities[], total: number }>> {
      return withErrorHandling(RestApi.axiosGet(AUTH_ENDPOINT, `/nonprofits`, {
        signal: controller?.signal,
        params: { ...params, perPage: 500 }
      }))
    }

    public static listNposNeedingConfig(
      controller?: AbortController,
    ): Promise<AxiosResponse<{ nonProfitOrgs: NPO[], total: number }>> {
      return withErrorHandling(RestApi.axiosGet(AUTH_ENDPOINT, `/nonprofits`, {
        signal: controller?.signal,
        params: { needDisbursementDetails: true }
      }))
    }

    public static searchPubNpos(
      abortController: AbortController, namelike: string, state?: string
    ): Promise<AxiosResponse<{ nonProfitOrgs: NPOWithActivities[], total: number }>> {
      return withErrorHandling(RestApi.axiosGet(AUTH_ENDPOINT, `/nonprofits/search`, {
        params: { namelike: namelike, state: state }, signal: abortController.signal
      }), { data: { nonProfitOrgs: [], total: 0 }, status: 200 } as AxiosResponse)
    }

    /**********************************
     * NONPROFITS Managed by a Team   *
     **********************************/

    public static createNpoForTeam(npo: NPO, orgUnitId: string) {
      const { id, ...npoRequest } = npo
      return withErrorHandling(RestApi.post(AUTH_ENDPOINT, `/orgunit/${orgUnitId}/nonprofit`, { body: npoRequest }))
    }
    public static listNposForTeam(orgUnitId: string) {
      return withErrorHandling(RestApi.get(AUTH_ENDPOINT, `/orgunit/${orgUnitId}/nonprofits`, {}))
    }

    /************************/
    /*   ORG UNITS / TEAMS  */
    /************************/

    // get orgunit by handle (not authenticated)
    public static getOrgUnit(handle: string) {
      if (!handle) throw new Error("handle cannot be undefined")
      return withErrorHandling<OrgUnitResponse>(RestApi.get(ENDPOINT, `/orgunit/${handle}`, {}))
    }

    // get orgunit with user role (owner, coordinator, member, etc) (authenticated user)
    public static getOrgUnitWithAuth(handle: string) {
      if (!handle) throw new Error("handle cannot be undefined")
      return withErrorHandling<OrgUnitResponse>(RestApi.get(AUTH_ENDPOINT, `/orgunit/${handle}`, {}))
    }

    public static getOrgUnitById(id: string) {
      return withErrorHandling(RestApi.get(ENDPOINT, `/orgunit/id/${id}`, {}))
    }

    public static getOrgUnitByIdWithAuth(id: string) {
      return withErrorHandling<OrgUnitResponse>(RestApi.get(AUTH_ENDPOINT, `/orgunit/id/${id}`, {}))
    }

    // searching teams requires you to be logged in
    public static listOrgUnits(namelike?: string, statuses: OrgStatus[] = []) {
      return withErrorHandling<{ orgUnits: Required<OrgUnit>[] }>(RestApi.get(AUTH_ENDPOINT, "/orgunits", {
        queryStringParameters: { namelike: namelike, status: statuses }
      }))
    }

    public static createOrgUnit(orgUnit: OrgUnit, employerName?: string) {
      const { id, ...orgUnitRequest } = orgUnit
      const orgUnitRequestWithEmployer = Object.assign({}, orgUnitRequest, { employerName: employerName } )
      return withErrorHandling<OrgUnitResponse>(RestApi.post(AUTH_ENDPOINT, "/orgunit", { body: orgUnitRequestWithEmployer }))
    }

    public static updateOrgUnit(orgUnit: OrgUnit, employerName?: string) {
      const { id, ...orgUnitRequest } = orgUnit
      const orgUnitRequestWithEmployer = Object.assign({}, orgUnitRequest, { employerName: employerName } )
      return withErrorHandling<OrgUnitResponse>(RestApi.put(AUTH_ENDPOINT, `/orgunit/${id}`, { body: orgUnitRequestWithEmployer }))
    }

    public static updateOrgUnitConfig(id: string, orgUnitConfig: OrgUnitConfig) {
      return withErrorHandling<OrgUnitResponse>(RestApi.put(AUTH_ENDPOINT, `/orgunit/${id}/config`, { body: orgUnitConfig }))
    }

    public static updateOrgUnitInternalConfig(id: string, orgUnitInternalConfig: OrgUnitInternalConfig) {
      return withErrorHandling<OrgUnitResponse>(RestApi.put(AUTH_ENDPOINT, `/wizard/orgunit/${id}/internalconfig`, { body: orgUnitInternalConfig }))
    }

    /********************/
    /*    ACTIVITIES    */
    /*  SCHED ACTIVITIES */
    /********************/

    public static createScheduledActivity(npoId: string, scheduledActivity: ScheduledActivity) {
      const { id, ...scheduledActivityRequest } = scheduledActivity
      return withErrorHandling(RestApi.post(AUTH_ENDPOINT, `/nonprofit/${npoId}/scheduledActivity`, { body: scheduledActivityRequest }))
    }

    public static updateScheduledActivity(npoId: string, scheduledActivityId: string, scheduledActivity: ScheduledActivity) {
      return withErrorHandling(RestApi.put(AUTH_ENDPOINT, `/nonprofit/${npoId}/scheduledActivity/${scheduledActivityId}`, {
        body: scheduledActivity
      }))
    }

    public static enableScheduledActivityLink(scheduledActivityId: string, orgUnitId?: string) {
      const queryParams = { sharing: ScheduledActivitySharing.Enabled, orgUnitId: orgUnitId }
      return withErrorHandling(RestApi.put(AUTH_ENDPOINT, `/scheduledActivity/${scheduledActivityId}/link`, {
        queryStringParameters: removeUndefinedProperties(queryParams),
      }))
    }

    public static disableScheduledActivityLink(scheduledActivityId: string, orgUnitId?: string) {
      const queryParams = { sharing: ScheduledActivitySharing.Disabled, orgUnitId: orgUnitId }
      return withErrorHandling(RestApi.put(AUTH_ENDPOINT, `/scheduledActivity/${scheduledActivityId}/link`, {
        queryStringParameters: removeUndefinedProperties(queryParams),
      }))
    }

    public static enableCampaignLink(campaignId: string) {
      return withErrorHandling(RestApi.put(AUTH_ENDPOINT, `/campaign/${campaignId}/link`, {
        queryStringParameters: { sharing: ScheduledActivitySharing.Enabled }
      }))
    }

    public static disableCampaignLink(campaignId: string) {
      return withErrorHandling(RestApi.put(AUTH_ENDPOINT, `/campaign/${campaignId}/link`, {
        queryStringParameters: { sharing: ScheduledActivitySharing.Disabled }
      }))
    }

    public static enableScheduledActivityGuestSharing(scheduledActivityId: string, orgUnitId?: string) {
      return withErrorHandling(RestApi.put(AUTH_ENDPOINT, `/scheduledActivity/${scheduledActivityId}/guest`, {
        queryStringParameters: { sharing: ScheduledActivitySharing.Enabled, orgUnitId: orgUnitId },
      }))
    }

    public static disableScheduledActivityGuestSharing(scheduledActivityId: string, orgUnitId?: string) {
      return withErrorHandling(RestApi.put(AUTH_ENDPOINT, `/scheduledActivity/${scheduledActivityId}/guest`, {
        queryStringParameters: { sharing: ScheduledActivitySharing.Disabled, orgUnitId: orgUnitId },
      }))
    }

    public static createScheduledActivityGuestLink(scheduledActivityId: string, orgUnitId?: string) {
      return withErrorHandling(RestApi.post(AUTH_ENDPOINT, `/scheduledActivity/${scheduledActivityId}/guestTokens`, {
        queryStringParameters: { orgUnitId: orgUnitId }
      }))
    }

    public static deleteScheduledActivityGuestLink(scheduledActivityId: string, guestTokenId: string, orgUnitId?: string) {
      return withErrorHandling(RestApi.del(AUTH_ENDPOINT, `/scheduledActivity/${scheduledActivityId}/guestTokens/${guestTokenId}`, {
        queryStringParameters: { orgUnitId: orgUnitId }
      }))
    }

    public static listScheduledActivityGuestLinks(scheduledActivityId: string, orgUnitId?: string ) {
      return withErrorHandling(RestApi.get(AUTH_ENDPOINT, `/scheduledActivity/${scheduledActivityId}/guestTokens`, {
        queryStringParameters: { orgUnitId: orgUnitId }
      }))
    }

    public static shareScheduledActivityToTeam(scheduledActivityId: string, orgUnitId: string, message: string) {
      return withErrorHandling(RestApi.post(AUTH_ENDPOINT, `/scheduledActivity/${scheduledActivityId}/share`, {
        body: { orgUnitId: orgUnitId, message: message }
      }))
    }

    public static getScheduledActivity(saId: string) {
      return withErrorHandling(RestApi.get(ENDPOINT, `/scheduledActivity/${saId}`, {}), undefined, eventCustomErrorMessages)
    }

    public static getScheduledActivityWithAuth(saId: string) {
      return withErrorHandling(RestApi.get(AUTH_ENDPOINT, `/scheduledActivity/${saId}`, {}), undefined, eventCustomErrorMessages)
    }

    public static async getScheduledActivityWithToken(token: string) {
      type unauthenticatedResponse = { npoName?: string, orgUnitName?: string, activityName?: string, timestamp?: string }
      const signInMessage = (response?: unauthenticatedResponse): string | undefined => {
        if (response?.npoName && response?.orgUnitName && response?.timestamp && response?.activityName) {
          const message = [
            `**${response.orgUnitName}** has invited you to join their Team on Field Day.`,
            `Please create an account or sign in to view the Team details and the`,
            `upcoming **${response.activityName}** event with`,
            `**${response.npoName}** on **${tsFormatDate(response.timestamp)}**.`,
          ].join(" ")
          return message
        }
      }
      const customErrorMessages = (status?: number, data?: any) => { switch (status) { case 401: return signInMessage(data) }}
      return withErrorHandling(RestApi.get(ENDPOINT, `/scheduledActivity/${token}`, {}), undefined, customErrorMessages)
    }

    public static getScheduledActivityWithTokenAuth(token: string) {
      return withErrorHandling(RestApi.get(AUTH_ENDPOINT, `/scheduledActivity/${token}`, {}))
    }

    public static async getScheduledActivityWithGuestToken(token: string) {
      type unauthenticatedResponse = { npoName?: string, orgUnitName?: string, activityName?: string, timestamp?: string }
      const signInMessage = (response?: unauthenticatedResponse): string | undefined => {
        if (response?.npoName && response?.orgUnitName && response?.timestamp && response?.activityName) {
          const message = [
            `**${response.orgUnitName}** has invited you to join their event on Field Day as a guest.`,
            `Please create an account or sign in to view the Team details and the`,
            `upcoming **${response.activityName}** event with`,
            `**${response.npoName}** on **${tsFormatDate(response.timestamp)}**.`,
          ].join(" ")
          return message
        }
      }
      const customErrorMessages = (status?: number, data?: any) => { switch (status) { case 401: return signInMessage(data) }}
      return withErrorHandling(RestApi.get(ENDPOINT, `/scheduledActivity/${token}`, {}), undefined, customErrorMessages)
    }

    public static async listScheduledActivityImages(
      saId: string,
      controller?: AbortController,
      orgUnitId?: string | null,
    ): Promise<AxiosResponse<ScheduledActivityImage[]>> {
      return withErrorHandling(RestApi.axiosGet(AUTH_ENDPOINT, `/scheduledActivity/${saId}/images`,
        { signal: controller?.signal, params: { orgUnitId: orgUnitId }}),
        { data: [], status: 200, headers: {}} as AxiosResponse)
    }

    public static listSurveyQuestions(saId: string) {
      return withErrorHandling<SurveyQuestionsResponse>(RestApi.get(AUTH_ENDPOINT, `/scheduledActivity/${saId}/surveyQuestions`, {}))
    }

    public static postSurveyAnswer(questionId: string, registrationId: string, answer: SurveyAnswerModelBase) {
      return withErrorHandling<SurveyAnswerResponse>(RestApi.post(AUTH_ENDPOINT, `/answer/${questionId}/${registrationId}`, { body: answer }))
    }

    public static listSurveyResponses(saId: string, orgType: string) {
      return withErrorHandling<SurveyReportResponse>(RestApi.get(AUTH_ENDPOINT, `/scheduledActivity/${saId}/surveyResponses/${orgType}`, {}))
    }

    public static publishScheduledActivities(npoId: string, saIds: string[]) {
      return withErrorHandling(RestApi.post(AUTH_ENDPOINT, `/nonprofit/${npoId}/publishScheduledActivities`, { body: { ids: saIds }}))
    }

    public static publishManagedScheduledActivities(orgUnitId: string, saIds: string[]) {
      return withErrorHandling(RestApi.post(AUTH_ENDPOINT, `/orgunit/${orgUnitId}/publishScheduledActivities`, { body: { ids: saIds }}))
    }

    public static cancelScheduledActivities(npoId: string, saIds: string[], reason?: string) {
      return withErrorHandling(RestApi.post(AUTH_ENDPOINT, `/nonprofit/${npoId}/cancelScheduledActivities`, {
        body: { ids: saIds, reason: reason },
      }))
    }

    public static cancelManagedScheduledActivities(orgUnitId: string, saIds: string[], reason?: string) {
      return withErrorHandling(RestApi.post(AUTH_ENDPOINT, `/orgunit/${orgUnitId}/cancelScheduledActivities`, {
        body: { ids: saIds, reason: reason },
      }))
    }

    public static async listActivityTemplates(
      params: ActivityQueryParams,
      abortController?: AbortController,
    ) {
      try {
        const resp = await withErrorHandling<AxiosResponse<ActivitiesResponse>>(RestApi.axiosGet(ENDPOINT, `/activities`, {
          params: params,
          signal: abortController?.signal,
        }), { data: { activities: [], nonProfitOrgs: []}, status: 200 } as AxiosResponse)
        return FieldDayAPI.mergeActivities(resp.data)
      } catch (err) {
        return Promise.reject(err)
      }
    }

    public static listActivitiesForNpo(npoId: string) {
      return withErrorHandling(RestApi.get(AUTH_ENDPOINT, `/nonprofit/${npoId}/activity`, {}))
    }

    public static listActivitiesForOu(orgUnitId: string, npoId: string) {
      return withErrorHandling(RestApi.get(AUTH_ENDPOINT, `/orgunit/${orgUnitId}/activity`, {
        queryStringParameters: { npoId: npoId }
      }))
    }

    public static getActivity(npoId: string, activityId: string) {
      return withErrorHandling(RestApi.get(ENDPOINT, `/nonprofit/${npoId}/activity/${activityId}`, {}))
    }

    public static getActivityWithAuth(npoId: string, activityId: string) {
      return withErrorHandling(RestApi.get(AUTH_ENDPOINT, `/nonprofit/${npoId}/activity/${activityId}`, {}))
    }

    public static createActivity(npoId: string, activity: Activity) {
      return withErrorHandling(RestApi.post(AUTH_ENDPOINT, `/nonprofit/${npoId}/activity`, { body: activity }))
    }

    public static updateActivity(npoId: string, activityId: string, activity: Activity) {
      const { nonProfitOrgId, ...activityRequest } = activity
      return withErrorHandling(RestApi.put(AUTH_ENDPOINT, `/nonprofit/${npoId}/activity/${activityId}`, { body: activityRequest }))
    }

    public static async listScheduledActivities(
      params: ScheduledActivityQueryParams, controller?: AbortController
    ): Promise<ScheduledActivityForView[]> {
      if (typeof params.startDate === "object") params.startDate = params.startDate.format(APIDATEFORMAT)
      if (typeof params.endDate   === "object") params.endDate   = params.endDate.format(APIDATEFORMAT)

      return withErrorHandling<AxiosResponse<ScheduledActivitiesResponse>>(RestApi.axiosGet(ENDPOINT,
          `/scheduledActivities`, { params: params, signal: controller?.signal }),
          { data: emptyListSAResp, status: 200, headers: {}} as AxiosResponse)
        .then(resp => FieldDayAPI.mergeScheduledActivities(resp.data))
    }

    public static async listScheduledActivitiesV2(
      params: ScheduledActivityQueryParams, controller?: AbortController
    ): Promise<MergedScheduledActivitiesResponse> {
      if (typeof params.startDate === "object") params.startDate = params.startDate.format(APIDATEFORMAT)
      if (typeof params.endDate   === "object") params.endDate   = params.endDate.format(APIDATEFORMAT)

      const resp: AxiosResponse<ScheduledActivitiesResponse> = await withErrorHandling(
        RestApi.axiosGet(ENDPOINT,
          `/scheduledActivities`, { params: params, signal: controller?.signal }),
          { data: emptyListSAResp, status: 200, headers: {}} as AxiosResponse)

      return {
      mergedScheduledActivities: FieldDayAPI.mergeScheduledActivities(resp.data),
      memberships: resp.data.memberships,
      total: resp.data.total
    }
    }

    public static async listScheduledActivitiesWithAuth(
      params: ScheduledActivityQueryParams, controller?: AbortController
    ): Promise<ScheduledActivityForView[]> {
      if (typeof params.startDate === "object") params.startDate = params.startDate.format(APIDATEFORMAT)
      if (typeof params.endDate   === "object") params.endDate   = params.endDate.format(APIDATEFORMAT)
      const resp = await withErrorHandling(RestApi.axiosGet(AUTH_ENDPOINT,
        `/scheduledActivities`, { params: params, signal: controller?.signal }),
        { data: emptyListSAResp, status: 200, headers: {}} as AxiosResponse)
      return FieldDayAPI.mergeScheduledActivities(resp.data)
    }

    // updated for pagination
    public static async listScheduledActivitiesWithAuthV2(
      params: ScheduledActivityQueryParams, controller?: AbortController
    ): Promise<MergedScheduledActivitiesResponse> {
      if (typeof params.startDate === "object") params.startDate = params.startDate.format(APIDATEFORMAT)
      if (typeof params.endDate   === "object") params.endDate   = params.endDate.format(APIDATEFORMAT)
      const resp: AxiosResponse<ScheduledActivitiesResponse> = await withErrorHandling(RestApi.axiosGet(AUTH_ENDPOINT,
        `/scheduledActivities`, { params: params, signal: controller?.signal }),
        { data: emptyListSAResp, status: 200, headers: {}} as AxiosResponse)
      return {
        mergedScheduledActivities: FieldDayAPI.mergeScheduledActivities(resp.data),
        memberships: resp.data.memberships,
        total: resp.data.total
      }
    }

    /***************************/
    /*    IMPACT CAMPAIGNS     */
    /***************************/

    public static createCampaign(orgUnitId: string, campaign: CampaignModelI): Promise<{ campaign: Campaign }> {
      return withErrorHandling(RestApi.post(AUTH_ENDPOINT, `/orgunit/${orgUnitId}/campaign`, { body: campaign }))
    }

    public static updateCampaign(orgUnitId: string, campaignId: string, campaign: CampaignModelI): Promise<{ campaign: Campaign }> {
      return withErrorHandling(RestApi.put(AUTH_ENDPOINT, `/orgunit/${orgUnitId}/campaign/${campaignId}`, { body: campaign }))
    }

    public static async getCampaignWithAuth(orgUnitId: string, campaignId: string): Promise<{
      campaign: Campaign, donationMatchPolicy?: DonationMatchPolicy, aggregatedNpoDonations?: NpoDonationAggregates[]
    }> {
      return withErrorHandling(RestApi.get(AUTH_ENDPOINT, `/orgunit/${orgUnitId}/campaign/${campaignId}`, {}))
    }

    public static async getCampaignWithOrgUnitId(orgUnitId: string, campaignId: string): Promise<{ campaign: Campaign }> {
      return withErrorHandling(RestApi.get(ENDPOINT, `/orgunit/${orgUnitId}/campaign/${campaignId}`, {}))
    }

    public static async getCampaign(campaignId: string): Promise<{ campaign: Campaign, scheduledActivities: ScheduledActivityForView[] }> {
      return withErrorHandling(RestApi.get(ENDPOINT, `/campaign/${campaignId}`, {}))
    }

    public static async listCampaigns(orgUnitId: string, params?: CampaignQueryParams): Promise<{ campaigns: Campaign[] }> {
      return withErrorHandling(RestApi.get(AUTH_ENDPOINT, `/orgunit/${orgUnitId}/campaigns`, { queryStringParameters: params }))
    }

    public static async listCommunityCampaigns(): Promise<{ campaigns: Campaign[] }> {
      return withErrorHandling(RestApi.get(ENDPOINT, `/campaigns`, {}))
    }

    public static async listCampaignRegistrations(
      orgUnitId: string, campaignId: string
    ): Promise<{
      registrations: RegistrationResponse[],
      campaignParticipants: CampaignParticipant[],
      campaignMinutes: { minutes: number },
      donationStats?: DonationPolicyStats
    }> {
      return withErrorHandling(RestApi.get(AUTH_ENDPOINT, `/orgunit/${orgUnitId}/campaign/${campaignId}/registration`, {}))
    }

    public static async getCommunityCampaignStats(
      campaignId: string
    ): Promise<{ registrations: RegistrationResponse[], campaignMinutes: { minutes: number, usercount: number }}> {
      return withErrorHandling(RestApi.get(ENDPOINT, `/campaign/${campaignId}/registration`, {}))
    }

    public static async listCampaignTeams(campaignId: string) {
      return withErrorHandling(RestApi.get(ENDPOINT, `/campaign/${campaignId}/teams`, {}))
    }

    public static async deleteCampaignEvents(campaignId: string, orgUnitId: string, scheduledActivityIds?: string[]) {
      return withErrorHandling(RestApi.post(AUTH_ENDPOINT, `/orgunit/${orgUnitId}/campaign/${campaignId}/deleteEvents`, {
        body: { scheduledActivityIds: scheduledActivityIds }
      }))
    }

    /********************/
    /*    LOCATIONS     */
    /********************/

    public static listLocations(orgType: OrgType, oId: string) {
      return withErrorHandling(RestApi.get(AUTH_ENDPOINT, `/${orgType}/${oId}/location`, {}))
    }

    public static getLocation(orgType: OrgType, oId: string, locationId: string) {
      return withErrorHandling(RestApi.get(AUTH_ENDPOINT, `/${orgType}/${oId}/location/${locationId}`, {}))
    }

    public static createLocation(orgType: OrgType, oId: string, location: Activity) {
      return withErrorHandling(RestApi.post(AUTH_ENDPOINT, `/${orgType}/${oId}/location`, { body: location }))
    }

    public static updateLocation(orgType: OrgType, oId: string, location: Location) {
      const { id, nonProfitOrgId, ...locationRequest } = location
      return withErrorHandling(RestApi.put(AUTH_ENDPOINT, `/${orgType}/${oId}/location/${id}`, { body: locationRequest }))
    }

    public static async listOrgUnitLocations(orgUnitId: string): Promise<{ locations: Location[] }> {
      return withErrorHandling(RestApi.get(AUTH_ENDPOINT, `/orgunit/${orgUnitId}/location`, {}))
    }

    // List OU owned locations for a given nonprofit.
    public static listNpoLocationsForOu(orgUnitId: string, npoId: string): Promise<{ locations: Location[] }> {
      return withErrorHandling(RestApi.get(AUTH_ENDPOINT, `/orgunit/${orgUnitId}/location`, {
        queryStringParameters: { npoId: npoId }
      }))
    }

    /*************************/
    /* FIELD TRIP SCHEDULING */
    /*************************/

    public static async createFieldTripPlan(orgUnitId: string, fieldTripPlan: Partial<FieldTripPlanCreate>): Promise<FieldTripPlanResponse> {
      return await withErrorHandling(RestApi.post(AUTH_ENDPOINT, `/orgunit/${orgUnitId}/fieldTripPlan`, { body: fieldTripPlan }))
    }

    public static async createStubFieldTripPlan(orgUnitId: string, fieldTripPlan: FieldTripPlanCreateStub): Promise<FieldTripPlanResponse> {
      return await withErrorHandling(RestApi.post(AUTH_ENDPOINT, `/orgunit/${orgUnitId}/fieldTripPlanWithInvite`, { body: fieldTripPlan }))
    }

    public static async getOrgUnitFieldTripPlan(
      orgUnitId: string,
      ftpId: string,
      controller?: AbortController,
    ): Promise<AxiosResponse<FieldTripPlanResponse>> {
      const fieldTripPlanResponse: AxiosResponse<FieldTripPlanResponse> =
        await withErrorHandling(RestApi.axiosGet(AUTH_ENDPOINT, `/orgunit/${orgUnitId}/fieldTripPlan/${ftpId}`, { signal: controller?.signal }),
        { data: {}, status: 200, headers: {}} as AxiosResponse)
      return fieldTripPlanResponse
    }

    public static async getNonprofitFieldTripPlan(
      npoId: string,
      ftpId: string,
      controller?: AbortController,
    ): Promise<AxiosResponse<FieldTripPlanResponse>> {
      const fieldTripPlanResponse: AxiosResponse<FieldTripPlanResponse> =
        await withErrorHandling(RestApi.axiosGet(AUTH_ENDPOINT, `/nonprofit/${npoId}/fieldTripPlan/${ftpId}`, { signal: controller?.signal }),
        { data: {}, status: 200, headers: {}} as AxiosResponse)
      return fieldTripPlanResponse
    }

    public static async listFieldTripPlans(
      resourceId: string,
      states: FieldTripPlanState[],
      page: number,
      perPage: number,
      resourceType: UserOrgType,
      abortController?: AbortController,
    ): Promise<AxiosResponse<FieldTripPlanListResponse[]>> {
      return await withErrorHandling(RestApi.axiosGet(AUTH_ENDPOINT, `/${resourceType}/${resourceId}/fieldTripPlan`, {
        signal: abortController?.signal,
        params: { state: states, page, perPage },
      }), { data: [], status: 200, headers: {}} as AxiosResponse)
    }

    public static async listOrgUnitFieldTripPlans(
      orgUnitId: string,
      states: FieldTripPlanState[],
      page: number,
      perPage: number,
      abortController?: AbortController
    ): Promise<AxiosResponse<FieldTripPlanListResponse[]>> {
      return await FieldDayAPI.listFieldTripPlans(orgUnitId, states, page, perPage, UserOrgType.OrgUnit, abortController)
    }

    public static async listNonprofitFieldTripPlans(
      npoId: string,
      states: FieldTripPlanState[],
      page: number,
      perPage: number,
      abortController?: AbortController
    ): Promise<AxiosResponse<FieldTripPlanListResponse[]>> {
      return await FieldDayAPI.listFieldTripPlans(npoId, states, page, perPage, UserOrgType.Nonprofit, abortController)
    }

    public static async wizardListFieldTripPlans(
      states: FieldTripPlanState[],
      reminderStates: FieldTripPlanReminderState[],
      orgUnitIds: string[],
      npoIds: string[],
      page: number,
      perPage: number,
    ):
        Promise<FieldTripPlanListResponse[]> {
      return await withErrorHandling(RestApi.get(AUTH_ENDPOINT, "/wizard/fieldTripPlans", {
        queryStringParameters: {
          state: states, reminderState: reminderStates, orgUnitId: orgUnitIds, nonProfitOrgId: npoIds,
          page, perPage
        }
      }))
    }

    public static async wizardListUsers(
      queryParams: { userId?: string, emaillike?: string, namelike?: string, page?: number, perPage?: number, sort?: string }
    ) {
      return withErrorHandling(RestApi.get(AUTH_ENDPOINT, "/wizard/users", { queryStringParameters: queryParams }))
    }

    public static async orgUnitUpdateFieldTripPlan(
      orgUnitId: string, ftpId: string, fieldTripPlanUpdate: Partial<FieldTripPlanUpdate>, version?: number
    ): Promise<FieldTripPlanResponse> {
      fieldTripPlanUpdate.version = version
      return await withErrorHandling(RestApi.put(AUTH_ENDPOINT, `/orgunit/${orgUnitId}/fieldTripPlan/${ftpId}`, { body: fieldTripPlanUpdate }))
    }

    public static async nonprofitUpdateFieldTripPlan(
      npoId: string, ftpId: string, fieldTripPlanUpdate: Partial<FieldTripPlanUpdate>, version?: number
    ): Promise<FieldTripPlanResponse> {
      fieldTripPlanUpdate.version = version
      return await withErrorHandling(RestApi.put(AUTH_ENDPOINT, `/nonprofit/${npoId}/fieldTripPlan/${ftpId}`, { body: fieldTripPlanUpdate }))
    }

    /********************/
    /*   REGISTRATIONS  */
    /********************/

    // see all users registered for an activity
    public static listRegistrations(saId: string, params?: { page?: number, perPage?: number, confirmationStatus?: RegistrationConfirmationStatus }) {
      return withErrorHandling<ListRegistrationsResponse>(RestApi.get(AUTH_ENDPOINT, `/registration/${saId}`, { queryStringParameters: params }))
    }

    // get just logged-in user registration for an activity
    public static getRegistration(saId: string) {
      return withErrorHandling<GetRegistrationResponse>(RestApi.get(AUTH_ENDPOINT, `/registration/user/${saId}`, {}))
    }

    // if you already withdrew, then you need to reregister, otherwise create a new reg
    // to reg with a team association (for team share, not for Field Trip), use orgUnitId
    public static registerForActivity(saId: string, reregister: boolean = false, orgUnitId?: string) {
      return reregister ?
        withErrorHandling(RestApi.put(AUTH_ENDPOINT, `/registration/${saId}`, {
          body: { status: RegistrationStatus.Registered, orgUnitId: orgUnitId }
        })) :
        withErrorHandling(RestApi.post(AUTH_ENDPOINT, `/registration/${orgUnitId ? `${saId}/${orgUnitId}` : saId}`, {}))
    }

    public static withdrawRegistration(saId: string) {
      return withErrorHandling(RestApi.put(AUTH_ENDPOINT, `/registration/${saId}`, { body: {
        status: RegistrationStatus.Withdrawn
      }}))
    }

    public static markAsNoAttendance(saId: string) {
      return withErrorHandling(RestApi.put(AUTH_ENDPOINT, `/registration/${saId}`, { body: {
        status: RegistrationStatus.NoAttendance
      }}))
    }

    public static addTeamPointOfContact(saId: string, userId: string) {
      return withErrorHandling(RestApi.put(AUTH_ENDPOINT, `/pointofcontact/${saId}`, { body: {
        userId: userId,
        contactStatus: RegistrationContactStatus.TeamContact
      }}))
    }

    public static removeTeamPointOfContact(saId: string, userId: string) {
      return withErrorHandling(RestApi.put(AUTH_ENDPOINT, `/pointofcontact/${saId}`, { body: {
        userId: userId,
        contactStatus: RegistrationContactStatus.None
      }}))
    }

    public static updateAttendance(saId: string, registrations: RegistrationModelI[]) {
      return withErrorHandling(RestApi.put(AUTH_ENDPOINT, `/scheduledActivity/${saId}/attendance`, { body: { registrations }}))
    }

    public static updateRegistrationConfirmations(confirmed: string[], unconfirmed: string[]): Promise<AxiosResponse> {
      const confirmedRequests = confirmed.map(id => {
        return { id: id, confirmationStatus: RegistrationConfirmationStatus.Confirmed }
      })
      const unconfirmedRequests = unconfirmed.map(id => {
        return { id: id, confirmationStatus: RegistrationConfirmationStatus.Unconfirmed }
      })
      const requestBody: ConfirmRegistrationsModel = {
        registrations: [...confirmedRequests, ...unconfirmedRequests]
      }

      return withErrorHandling(RestApi.axiosPut(AUTH_ENDPOINT, "/wizard/confirmRegistrations", requestBody))
    }

    /********************************/
    /*   SELF-REPORTED FIELD TRIPS  */
    /********************************/
    public static postEventReport(orgUnitId: string, volunteerEventReport: VolunteerEventReportModelI) {
      return withErrorHandling(RestApi.post(AUTH_ENDPOINT, `/orgUnit/${orgUnitId}/volunteerEventReport`, {
        body: volunteerEventReport
      }))
    }

    public static putEventReport(orgUnitId: string, scheduledActivityId: string, volunteerEventReport: VolunteerEventReportModelI) {
      return withErrorHandling(RestApi.put(AUTH_ENDPOINT, `/orgUnit/${orgUnitId}/volunteerEventReport/${scheduledActivityId}`, {
        body: volunteerEventReport
      }))
    }

    public static putEventReportApproval(orgUnitId: string, scheduledActivityId: string,
        reviewVolunteerReport: ReviewVolunteerEventReportModelI) {
      return withErrorHandling(RestApi.put(AUTH_ENDPOINT, `/orgUnit/${orgUnitId}/volunteerEventReport/${scheduledActivityId}/approval`, {
        body: reviewVolunteerReport
      }))
    }

    /************************/
    /*  MANGED FIELD TRIPS  */
    /************************/

    public static createManagedFieldTrip(
      orgUnit: OrgUnit,
      requestBody: RecursivePartial<ManagedFieldTripModelI>,
    ): Promise<{ scheduledActivity: ScheduledActivityForView }> {
      return withErrorHandling(RestApi.post(AUTH_ENDPOINT, `/orgUnit/${orgUnit.id}/managedFieldTrips`, {
        body: requestBody
      }))
    }

    public static updateManagedFieldTrip(
      orgUnit: OrgUnit,
      requestBody: RecursivePartial<ManagedFieldTripModelI>,
      scheduledActivityId?: string,
    ) {
      return withErrorHandling(RestApi.put(AUTH_ENDPOINT, `/orgUnit/${orgUnit.id}/managedFieldTrips/${scheduledActivityId}`, {
        body: requestBody
      }))
    }

    /******************/
    /*   MEMBERSHIPS  */
    /******************/

    public static listMemberships(
      orgType: OrgType,
      oId: string,
      queryParams?: MembershipQueryParams,
      controller?: AbortController
      ): Promise<AxiosResponse<MembershipsResponse>> {
      return withErrorHandling(RestApi.axiosGet(AUTH_ENDPOINT, `/membership/${orgType}/${oId}`,
        { signal: controller?.signal, params: queryParams, }),
        { data: { memberships: [], total: 0 }, status: 200 } as AxiosResponse)
    }

    public static updateMembership(orgType: OrgType, oId: string, userEmail: string, role: string, metadata?: MembershipMetadata ) {
      const body = {
        userEmail:      userEmail,
        role:           role,
        nonProfitOrgId: orgType === "nonprofit" ? oId : null,
        orgUnitId:      orgType === "orgunit"   ? oId : null,
        metadata:       metadata,
      }
      return withErrorHandling(RestApi.post(AUTH_ENDPOINT, `/membership`, { body: body }))
    }

    public static createMembershipWithInviteToken(
      inviteToken: string,
      joinEvent?: boolean,
      customErrorRedirect?: string,
      ignoreIncomplete?: boolean,
    ): Promise<{ registration: Registration, orgRole?: UserRole, message?: string }> {
      const queryParams: { joinEvent?: string, ignoreIncomplete?: string } = joinEvent ? { joinEvent: "true" } : {}
      if (ignoreIncomplete) { queryParams.ignoreIncomplete = "true" }
      return withErrorHandling(RestApi.post(AUTH_ENDPOINT, `/membership/${inviteToken}`, {
        queryStringParameters: queryParams,
      }), undefined, undefined, customErrorRedirect)
    }

    public static createRegistrationWithGuestToken(
      guestToken: string,
      customErrorRedirect?: string,
      ignoreIncomplete?: boolean,
    ): Promise<{ registration: Registration, orgRole?: UserRole, message?: string }> {
      const queryParams = ignoreIncomplete ? { ignoreIncomplete: "true" } : {}
      return withErrorHandling(RestApi.post(AUTH_ENDPOINT, `/registration/${guestToken}`, {
        queryStringParameters: queryParams,
      }), undefined, undefined, customErrorRedirect)
    }

    public static createMembership(orgType: OrgType, oId: string) {
      return withErrorHandling(RestApi.post(AUTH_ENDPOINT, `/${orgType}/${oId}/membership`, {}))
    }

    /*******************/
    /*  ORG INVITATIONS */
    /*******************/

    public static async bulkInviteToOrg(orgType: OrgType, oId: string, emails: ReadonlyArray<string>, role?: UserRole) {
      const invitations: OrgInvitationModelI[] = emails.map(email => {
        return {
          userEmail: email,
          role: role ?? UserRole.Member,
        }
      })
      return withErrorHandling(RestApi.post(AUTH_ENDPOINT, `/${orgType}/${oId}/invitations`, { body: invitations }))
    }

    public static async getOrgInvitations(orgType: OrgType, oId: string): Promise<OrgInvitationModelResponse[]> {
      return withErrorHandling(RestApi.get(AUTH_ENDPOINT, `/${orgType}/${oId}/invitations`, {}))
    }

    /******************/
    /*  NOTIFICATIONS  */
    /******************/

    // TODO: specify response interface
    public static listUserNotifications(page: number, perPage: number) {
      return withErrorHandling(RestApi.get(AUTH_ENDPOINT, `/notifications`, { queryStringParameters: { page, perPage }}))
    }

    public static getUnreadNotificationCount() {
      // cut down on noise - no need to log errors or retry on notification countss
      return RestApi.axiosGetNotifications(AUTH_ENDPOINT, `/notifications/count`, {})
    }

    public static markNotificationsRead(notifIds: string[]) {
      return withErrorHandling(RestApi.put(AUTH_ENDPOINT, `/notifications`, { body: { ids: notifIds }}))
    }

    public static markFtpNotificationsRead(ftpId: string) {
      return withErrorHandling(RestApi.put(AUTH_ENDPOINT, `/notifications`, { body: { fieldTripPlanId: ftpId }}))
    }

    /*****************************/
    /*    FEEDBACK FORM    */
    /*****************************/
    public static feedbackFormRequest(
      user: UserInfo, url: string, message: string
    ): Promise<AxiosResponse<{ message: string }>> {
      const req = `New Feedback Submission
        Submitted by: ${user.displayName}
        Email: ${user.email}
        Page: ${url}

        Message: ${message}
      `.replace(/^ +| +$/gm, "")

      return withErrorHandling(RestApi.axiosPost(AUTH_ENDPOINT, "/tempFieldTripRequest", req, {
        headers: { 'Content-Type': 'text/plain' }}))
    }

    // This method is a wrapper to construct a scheduled activities api call with correct query params
    // if called with all default params eg: getActivitiesList(), it will return all published upcoming scheduled activities
    // optionally can include an npoid, status (DRAFT || PUBLISHED) and a boolean past to list scheduled activities that are in the past
    public static async getNonprofitActivitiesList(
      npoid: string,
      status: string = ActivityStatus.Published,
      past: boolean = false,
      params: ScheduledActivityQueryParams = {}
    ): Promise<ScheduledActivityForView[]> {
      return this.getActivitiesList(status, past, { ...params, npoId: npoid })
    }

    public static async getOrgUnitActivitiesList(
      orgUnitId: string,
      status: string = ActivityStatus.Published,
      past: boolean = false,
      params: ScheduledActivityQueryParams = {}
    ): Promise<ScheduledActivityForView[]> {
      return this.getActivitiesList(status, past, { ...params, orgUnitId: orgUnitId })
    }

    public static async getNonprofitActivitiesForCalendar(
      nonProfitOrgId: string,
      status: string = ActivityStatus.Published,
      params: ScheduledActivityQueryParams = {}
    ): Promise<ScheduledActivityForView[]> {
      return this.listScheduledActivitiesWithAuth({
        ...params,
        npoId: nonProfitOrgId,
        status: status,
      })
    }

    public static async getOrgUnitActivitiesForCalendar(
      orgUnitId: string,
      status: string = ActivityStatus.Published,
      params: ScheduledActivityQueryParams = {}
    ): Promise<ScheduledActivityForView[]> {
      return this.listScheduledActivitiesWithAuth({
        ...params,
        orgUnitId: orgUnitId,
        status: status,
      })
    }

    private static async getActivitiesList(
      status: string,
      past: boolean,
      params: ScheduledActivityQueryParams,
    ): Promise<ScheduledActivityForView[]> {
      // if looking up drafts or using the "include" param, must be a logged-in user
      const listScheduledActivities = status === ActivityStatus.Draft || params.include !== undefined ?
        this.listScheduledActivitiesWithAuth :
        this.listScheduledActivities;
      // set defaults for dateparams if they are missing
      const dateparams = past ? { startDate: params.startDate || dayjs('2022').startOf('year'),
                                  endDate:   params.endDate   || dayjs() } :
                                { startDate: params.startDate || dayjs(),
                                  endDate:   params.endDate }
      return listScheduledActivities({
        ...params,
        past,
        ...dateparams,
        status: status,
      })
    }

    // private method that will populate the scheduledActivities with respective activities/locations
    // (to be used by auth/noauth listScheduledActivities)
    private static mergeScheduledActivities(resp?: ScheduledActivitiesResponse): ScheduledActivityForView[] {
      if (!resp?.scheduledActivities) return [] // probably a canceled request
      return resp.scheduledActivities.map((sa: ScheduledActivityForView) => {
        sa.activity = resp.activities.find((a: Activity) => a.id === sa.activityId)
        sa.location = resp.locations.find((l: Location) => l.id === sa.locationId)
        sa.nonProfitOrg = resp.nonProfitOrgs.find((n: NPO) => n.id === sa.nonProfitOrgId)
        sa.orgUnit = resp.orgUnits.find((o: OrgUnit) => o.id === sa.orgUnitId)
        return sa
      })
    }

    private static mergeActivities(resp: ActivitiesResponse) {
      return resp.activities.map((a: Activity) => {
        a.nonProfitOrg = resp.nonProfitOrgs.find((n: NPO) => n.id === a.nonProfitOrgId)
        return a
      })
    }

    /***********************************/
    /*   SCHEDULED ACTIVITY MESSAGES   */
    /***********************************/
    public static async createScheduledActivityMessage(
        saId: string,
        messageBody: string,
        parentMessageId?: string,
        type?: MessageType,
        orgUnitId?: string | null): Promise<CreateMessageResponse> {
      const createMessagePromise = withErrorHandling(RestApi.post(AUTH_ENDPOINT, `/scheduledActivity/${saId}/message`, {
        body: {
          parentMessageId: parentMessageId,
          body: messageBody,
          type: type,
          orgUnitId: orgUnitId,
        }
      }))

      return createMessagePromise
        .then(() => {return { success: true } as CreateMessageResponse})
        .catch(err => {
          const defaultErrorResp = {
            success: false,
            errorMessage: "Unable to post message. Please try again later.",
          } as CreateMessageResponse

          if (err.response.status >= 500) {
            return defaultErrorResp
          }

          if (err.response.data && err.response.data.errors) {
            for (const error of err.response.data.errors) {
              // If there are any recognized errors present, alert with the message returned from the API.
              if (error.schemaPath === "#/properties/body/errorMessage" || error.schemaPath === "#/properties/body/maxLength") {
                return {
                  success: false,
                  errorMessage: error.message,
                } as CreateMessageResponse
              } else if (err.response.status >= 400) {
                return {
                  success: false,
                  errorMessage: "Invalid message.",
                } as CreateMessageResponse
              }
            }
          } else if (err.response.data && err.response.data.message) {
            return {
              success: false,
              errorMessage: err.response.data.message,
            } as CreateMessageResponse
          }

          return defaultErrorResp
        })
    }

    public static async listScheduledActivityMessages(
      saId: string,
      controller?: AbortController,
      type?: MessageType,
      orgUnitId?: string,
    ): Promise<AxiosResponse<ScheduledActivityMessage[]>> {
      return withErrorHandling(RestApi.axiosGet(AUTH_ENDPOINT, `/scheduledActivity/${saId}/message`,
        { signal: controller?.signal, params: { type: type, orgUnitId: orgUnitId }}),
        { data: [], status: 200, headers: {}} as AxiosResponse)
    }

    /***************************/
    /*   MATCHABLE DONATIONS   */
    /***************************/

    public static async listUserOrgUnitDonations(orgUnitId: string, filter: MatchableDonationFilter): Promise<AxiosResponse<{
      donations: MatchableDonationModelResponse[],
      nonProfitOrgs: NPO[],
      users: UserInfo[],
      orgUnits: OrgUnit[],
      memberships: GroupMember[],
      total: number,
    }>> {
      const startDate = filter.startDate ? objFromTzAsUtc(`${filter.startDate}T00:00:00`) : undefined
      const endDate = filter.endDate ? objFromTzAsUtc(`${filter.endDate}T23:59:59`) : undefined
      return await withErrorHandling(RestApi.axiosGet(AUTH_ENDPOINT, `/orgunit/${orgUnitId}/matchabledonation`, { params: {
        ...filter,
        startDate: startDate?.toISOString(),
        endDate: endDate?.toISOString(),
      }}))
    }

    public static async listAllMatchableOrgUnitDonations(orgUnitId: string, filter: MatchableDonationFilter): Promise<AxiosResponse<{
      donations: MatchableDonationModelResponse[],
      nonProfitOrgs: NPO[],
      users: UserInfo[],
      orgUnits: OrgUnit[],
      memberships: GroupMember[],
      total: number,
    }>> {
      const startDate = filter.startDate ? objFromTzAsUtc(`${filter.startDate}T00:00:00`) : undefined
      const endDate = filter.endDate ? objFromTzAsUtc(`${filter.endDate}T23:59:59`) : undefined
      return await withErrorHandling(RestApi.axiosGet(AUTH_ENDPOINT, `/orgunit/${orgUnitId}/matchabledonation`, { params: {
        include: "all",
        ...filter,
        startDate: startDate?.toISOString(),
        endDate: endDate?.toISOString(),
      }}))
    }

    public static async listMyDonations(filter: MatchableDonationFilter): Promise<AxiosResponse<{
      donations: MatchableDonationModelResponse[],
      nonProfitOrgs: NPO[],
      users: UserInfo[],
      orgUnits: OrgUnit[],
      total: number,
    }>> {
      const startDate = filter.startDate ? objFromTzAsUtc(`${filter.startDate}T00:00:00`) : undefined
      const endDate = filter.endDate ? objFromTzAsUtc(`${filter.endDate}T23:59:59`) : undefined
      return await withErrorHandling(RestApi.axiosGet(AUTH_ENDPOINT, `/matchabledonation`, { params: {
        ...filter,
        startDate: startDate?.toISOString(),
        endDate: endDate?.toISOString(),
      }}))
    }

    public static async createPlatformDonationForTeam(
      orgUnitId: string,
      body: MatchableDonationModelI,
      confirmationTokenId: string,
    ): Promise<AxiosResponse<{
      donation: MatchableDonationModelResponse,
      paymentIntent: {
        clientSecret: string,
        status: string,
      }
    }>> {
      return await withErrorHandling(RestApi.axiosPost(AUTH_ENDPOINT, `/orgunit/${orgUnitId}/platformdonation`, {
        ...body,
        timeZone: guessedTimeZone,
        confirmationTokenId: confirmationTokenId,
      }))
    }

    public static async createPlatformDonationForEvent(
      scheduledActivityId: string,
      body: PlatformDonationModelI,
    ): Promise<AxiosResponse<{
      donation: PlatformDonationModelResponse,
      paymentIntent: {
        clientSecret: string,
        status: string,
      }
    }>> {
      return await withErrorHandling(
        RestApi.axiosPost(AUTH_ENDPOINT, `/scheduledActivity/${scheduledActivityId}/platformdonation`, body)
      )
    }

    public static async createMatchableDonation(
      orgUnitId: string,
      body: MatchableDonationModelI,
    ): Promise<AxiosResponse<{ donation: MatchableDonationModelResponse }>> {
      return await withErrorHandling(RestApi.axiosPost(AUTH_ENDPOINT, `/orgunit/${orgUnitId}/matchabledonation`, body))
    }

    public static async updateMatchableDonation(
      orgUnitId: string,
      matchableDonationId: string,
      body: MatchableDonationUpdateModelI,
    ): Promise<AxiosResponse<{ donation: MatchableDonationModelResponse }>> {
      return await withErrorHandling(RestApi.axiosPut(AUTH_ENDPOINT, `/orgunit/${orgUnitId}/matchabledonation/${matchableDonationId}`, body))
    }

    public static async createDonationReceiptUploadBatch(
      orgUnitId: string,
      matchableDonationId: string,
      fileNames: string[],
    ): Promise<AxiosResponse<{ receiptFiles: FileModelResponse[] }>> {
      return await withErrorHandling(RestApi.axiosPost(
        AUTH_ENDPOINT,
        `/orgunit/${orgUnitId}/matchabledonation/${matchableDonationId}/receiptuploadbatch`,
        { fileNames: fileNames },
      ))
    }

    public static async approveDonationForMatch(
      orgUnitId: string,
      matchableDonationId: string,
      amount?: number,
    ): Promise<AxiosResponse<{ donation: MatchableDonationModelResponse }>> {
      return await withErrorHandling(RestApi.axiosPut(AUTH_ENDPOINT, `/orgunit/${orgUnitId}/matchabledonation/${matchableDonationId}`, {
        approvalStatus: MatchApprovalStatus.Approved,
        approvedAmount: amount,
        approvedDateISO: objFromUtcAsTz(dayjs().toISOString()).format("YYYY-MM-DD"),
      }))
    }

    public static async unapproveDonationForMatch(
      orgUnitId: string,
      matchableDonationId: string,
    ): Promise<AxiosResponse<{ donation: MatchableDonationModelResponse }>> {
      return await withErrorHandling(RestApi.axiosPut(AUTH_ENDPOINT, `/orgunit/${orgUnitId}/matchabledonation/${matchableDonationId}`, {
        approvalStatus: MatchApprovalStatus.Rejected,
        approvedAmount: 0,
        approvedDateISO: null,
      }))
    }

    public static async confirmDonationMatched(
      orgUnitId: string,
      matchableDonationId: string,
      amount?: number,
    ): Promise<AxiosResponse<{ donation: MatchableDonationModelResponse }>> {
      return await withErrorHandling(RestApi.axiosPut(AUTH_ENDPOINT, `/orgunit/${orgUnitId}/matchabledonation/${matchableDonationId}`, {
        status: DonationStatus.Completed,
        matchedAmount: amount,
        matchedDateISO: objFromUtcAsTz(dayjs().toISOString()).format("YYYY-MM-DD"),
      }))
    }

    public static async unconfirmDonationMatched(
      orgUnitId: string,
      matchableDonationId: string,
    ): Promise<AxiosResponse<{ donation: MatchableDonationModelResponse }>> {
      return await withErrorHandling(RestApi.axiosPut(AUTH_ENDPOINT, `/orgunit/${orgUnitId}/matchabledonation/${matchableDonationId}`, {
        status: DonationStatus.Reported,
        matchedAmount: null,
        matchedDateISO: null,
      }))
    }

    public static async createOrgUnitDonation(
      orgUnitId: string,
      body: OrgUnitDonation,
    ): Promise<AxiosResponse<{ orgUnitDonation: OrgUnitDonation, nonProfitOrg: NPO }>> {
      return withErrorHandling(RestApi.axiosPost(AUTH_ENDPOINT, `/orgunit/${orgUnitId}/orgUnitDonation`, body ))
    }

    public static async updateOrgUnitDonation(
      orgUnitId: string,
      orgUnitDonationId: string,
      body: OrgUnitDonation,
    ): Promise<AxiosResponse<{ orgUnitDonation: OrgUnitDonation, nonProfitOrg: NPO }>> {
      return withErrorHandling(RestApi.axiosPut(AUTH_ENDPOINT, `/orgunit/${orgUnitId}/orgUnitDonation/${orgUnitDonationId}`, body ))
    }

    public static async listOrgUnitDonations(orgUnitId: string):
        Promise<AxiosResponse<{ orgUnitDonations: OrgUnitDonation[], nonProfitOrgs: NPO[] }>> {
      return withErrorHandling(RestApi.axiosGet(AUTH_ENDPOINT, `/orgunit/${orgUnitId}/orgUnitDonations` ))
    }

    public static async createDonationMatchPolicy(
      orgUnitId: string,
      body: DonationMatchPolicy,
    ): Promise<AxiosResponse<{ donationMatchPolicy: DonationMatchPolicy }>> {
      return await withErrorHandling(RestApi.axiosPost(AUTH_ENDPOINT, `/orgunit/${orgUnitId}/donationmatchpolicy`, body))
    }

    public static async updateDonationMatchPolicy(
      orgUnitId: string,
      donationMatchPolicyId: string,
      body: DonationMatchPolicy,
    ): Promise<AxiosResponse<{ donationMatchPolicy: DonationMatchPolicy }>> {
      return await withErrorHandling(RestApi.axiosPut(AUTH_ENDPOINT, `/orgunit/${orgUnitId}/donationmatchpolicy/${donationMatchPolicyId}`, body))
    }

    public static async listDonationMatchPolicies(
      orgUnitId: string,
      startDate?: string,
      endDate?: string,
      status?: DonationMatchPolicyStatus, // if undefined return all statuses
    ): Promise<AxiosResponse<{ donationMatchPolicies: DonationMatchPolicy[] }>> {
      return await withErrorHandling(RestApi.axiosGet(AUTH_ENDPOINT, `/orgunit/${orgUnitId}/donationmatchpolicy`, { params: {
        startDate: startDate,
        endDate: endDate,
        status: status
      }}))
    }

    /**
     * Donation batching
     */

    public static async previewDonationInvoiceBatch(
      endAt: string,
      abortController: AbortController,
    ): Promise<AxiosResponse<{
      donationInvoiceBatches: DonationInvoiceBatchModelResponseWithUpdatedAt[],
      orgUnits: OrgUnit[],
      nonProfitOrgs: NPO[],
    }>> {
      return await withErrorHandling(RestApi.axiosGet(AUTH_ENDPOINT, `/wizard/donationinvoicebatch/preview`, { params: {
        endAt: endAt,
      }, signal: abortController.signal }))
    }

    public static async listDonationInvoiceBatches(
      statuses: DonationInvoiceBatchStatus[],
      abortController: AbortController,
    ): Promise<AxiosResponse<{
      donationInvoiceBatches: DonationInvoiceBatchModelResponseWithUpdatedAt[],
      orgUnits: OrgUnit[],
      nonProfitOrgs: NPO[],
    }>> {
      return await withErrorHandling(RestApi.axiosGet(AUTH_ENDPOINT, "/wizard/donationinvoicebatch", { params: {
        status: statuses
      }, signal: abortController.signal }))
    }

    public static async createDonationInvoiceBatch(
      orgUnitId: string,
      endAt: string,
    ): Promise<AxiosResponse<{
      donationInvoiceBatch: DonationInvoiceBatchModelResponseWithUpdatedAt,
    }>> {
      return await withErrorHandling(RestApi.axiosPost(AUTH_ENDPOINT, `/wizard/orgunit/${orgUnitId}/donationinvoicebatch`, {
        endAt: endAt,
      }))
    }

    public static async updateDonationInvoiceBatch(
      donationInvoiceBatchId: string,
      body: DonationInvoiceBatchUpdateModel,
    ): Promise<AxiosResponse<{
      donationInvoiceBatch: DonationInvoiceBatchModelResponseWithUpdatedAt,
    }>> {
      return await withErrorHandling(RestApi.axiosPut(AUTH_ENDPOINT, `/wizard/donationinvoicebatch/${donationInvoiceBatchId}`, {
        ...body,
      }))
    }

    public static async removeDonationFromInvoiceBatch(
      donationInvoiceBatchId: string,
      matchableDonationId: string,
    ): Promise<AxiosResponse<unknown>> {
      return await withErrorHandling(
        RestApi.axiosDelete(AUTH_ENDPOINT, `/wizard/donationinvoicebatch/${donationInvoiceBatchId}/matchabledonation/${matchableDonationId}`)
      )
    }

    public static async previewDonationDisbursementBatch(
      endAt: string,
      abortController: AbortController,
    ): Promise<AxiosResponse<{
      nonProfitOrgDisbursementBatches: DonationDisbursementNonProfitOrgBatchResponseModelI[],
      nonProfitOrgs: NPO[],
    }>> {
      return await withErrorHandling(RestApi.axiosGet(AUTH_ENDPOINT, `/wizard/donationdisbursementbatch/preview`, { params: {
        endAt: endAt,
      }, signal: abortController.signal }))
    }

    public static async previewDonationDisbursementBatchMissingDetails(
      endAt: string,
      abortController: AbortController,
    ): Promise<AxiosResponse<{
      nonProfitOrgDisbursementBatches: DonationDisbursementNonProfitOrgBatchResponseModelI[],
      nonProfitOrgs: NPO[],
    }>> {
      return await withErrorHandling(RestApi.axiosGet(AUTH_ENDPOINT, `/wizard/donationdisbursementbatch/preview`, { params: {
        endAt: endAt,
        missingDisbursementDetails: true,
      }, signal: abortController.signal }))
    }

    public static async createDonationDisbursementBatch(
      endAt: string,
    ): Promise<AxiosResponse<{
      disbursementBatch: DonationDisbursementBatchResponseModelI,
      nonProfitOrgDisbursementBatches: DonationDisbursementNonProfitOrgBatchResponseModelI[],
      nonProfitOrgs: NPO[],
    }>> {
      return await withErrorHandling(RestApi.axiosPost(AUTH_ENDPOINT, `/wizard/donationdisbursementbatch`, {
        endAt: endAt,
      }))
    }

    public static async listDonationDisbursementBatches(
      statuses: DonationDisbursementBatchStatus[],
      abortController: AbortController,
    ): Promise<AxiosResponse<{
      disbursementBatches: DonationDisbursementBatchResponseModelI[],
      nonProfitOrgs: NPO[],
    }>> {
      return await withErrorHandling(RestApi.axiosGet(AUTH_ENDPOINT, `/wizard/donationdisbursementbatch`, { params: {
        status: statuses,
      }, signal: abortController.signal }))
    }

    public static async updateDonationDisbursementBatch(
      donationDisbursementBatchId: string,
      body: DonationDisbursementBatchUpdateModelI,
    ): Promise<AxiosResponse<{
      disbursementBatch: DonationDisbursementBatchResponseModelI,
    }>> {
      return await withErrorHandling(RestApi.axiosPut(AUTH_ENDPOINT, `/wizard/donationdisbursementbatch/${donationDisbursementBatchId}`, {
        ...body,
      }))
    }

    public static async deleteNonprofitFromDisbursementBatch(
      donationDisbursementBatchId: string,
      npoDisbursementBatchId: string,
    ): Promise<AxiosResponse<{
      disbursementBatch: DonationDisbursementBatchResponseModelI,
      nonProfitOrgDisbursementBatches: DonationDisbursementNonProfitOrgBatchResponseModelI[],
      nonProfitOrgs: NPO[],
    }>> {
      return await withErrorHandling(RestApi.axiosDelete(
        AUTH_ENDPOINT,
        `/wizard/donationdisbursementbatch/${donationDisbursementBatchId}/nonprofitdisbursementbatch/${npoDisbursementBatchId}`
      ))
    }
  }
}

async function buildAxiosClient(endpointName: string, timeout: number = 30000): Promise<AxiosInstance> {
  const authConfig = AWS_AUTH_CONFIG.API.endpoints.find(endpoint => endpoint.name === endpointName)
  const baseEndpoint = authConfig?.endpoint
  const customHeaderFunc = authConfig && authConfig?.custom_header && authConfig.custom_header()

  let customHeader = {}
  if (customHeaderFunc) {
    customHeader = await customHeaderFunc
  }

  return axios.create({
    baseURL: baseEndpoint,
    timeout: timeout,
    headers: customHeader,
  })
}

export interface OrgUnitDonation {
  id?: string
  amountInCents: number
  memo: string
  nonProfitOrgId: string
  status?: "Draft" | "Approved"
  createdAt?: string
}

export interface DonationInvoiceBatchModelResponseWithUpdatedAt extends DonationInvoiceBatchModelResponse {
  updatedAt: string,
}

// valid incoming query parameters from a search/list of activities
export interface ScheduledActivityQueryParams {
  status?: string;            // "DRAFT" or "PUBLISHED" (default)
  startDate?: string | Dayjs; // if Dayjs object, will be converted to ISO format YYYY-MM-DD
  endDate?: string | Dayjs;
  npoId?: string | string[];
  past?: boolean;
  saId?: string | string[];
  dow?: number | number[] | string | string[];    // day of week, Monday = 1
  actId?: string
  orgUnitId?: string | string[],
  include?: "public" | "private" | "mine",
  page?: number
  perPage?: number
  visibility?: ActivityVisibility | ActivityVisibility[]
  owner?: ScheduledActivityOwner | ScheduledActivityOwner[]
  brief?: "true"
}

export interface CampaignQueryParams {
  status?: string | string[]
  scheduledActivityId?: string
}

export interface MembershipQueryParams {
  namelike?: string,
  emaillike?: string,
  page?: number,
  perPage?: number,
  sort?: string,
}


/**********
 * Response objects (useful for when you don't want something better than Promise<any>)
 *********/

interface ScheduledActivitiesResponse {
  scheduledActivities: ScheduledActivityForView[]
  activities: Activity[]
  locations: Location[]
  nonProfitOrgs: NPO[]
  orgUnits: OrgUnit[]
  memberships?: GroupMember[]
  total: number
}

const emptyListSAResp: ScheduledActivitiesResponse = {
  scheduledActivities: [],
  activities: [],
  locations: [],
  nonProfitOrgs: [],
  orgUnits: [],
  total: 0,
}

export interface MergedScheduledActivitiesResponse {
  mergedScheduledActivities: ScheduledActivityForView[]
  memberships?: GroupMember[]
  total: number
}

interface ActivitiesResponse {
  activities: Activity[]
  nonProfitOrgs: NPO[]
}

interface SurveyQuestionsResponse {
  surveyQuestions: SurveyQuestion[]
  surveyAnswers:   SurveyAnswer[]
}

interface SurveyReportResponse {
  registrations: Registration[]
  scheduledActivity: ScheduledActivityForView
  surveyQuestions: SurveyQuestion[]
  surveyAnswers:   SurveyAnswer[]
  registrationQuestions: SurveyQuestion[]
  registrationAnswers:   SurveyAnswer[]
}

export interface SurveyAnswerResponse {
  answer:       SurveyAnswer
  registration: Registration
}

interface ListRegistrationsResponse {
  registrations: Required<Registration>[]
  orgUnits?: Required<OrgUnit>[]
  countsByOrgUnits: {
    orgUnitId: string
    participantCount: number
  }[]
}

interface GetRegistrationResponse {
  registration: Required<Registration>
}

interface CreateMessageResponse {
  success: boolean,
  errorMessage: string,
}

export interface OrgUnitResponse {
  message?: string,
  orgUnit: Required<OrgUnit>,
  role: UserRole,
  employer?: Employer
}

interface MembershipsResponse {
  memberships: MembershipWithUser[],
  total: number,
}

export interface MatchableDonationFilter {
  status?: DonationStatus,
  approvalStatus?: MatchApprovalStatus,
  startDate?: string,
  endDate?: string,
  page?: number,
  perPage?: number,
  brief?: string,
}

const eventCustomErrorMessages = (status?: number, data?: any) => {
  const eventSignInMessage = (response?: { npoName?: string, activityName?: string }): string | undefined => {
    if (response?.npoName && response.activityName) {
      return `Please sign in to view this Field Trip (**${response.activityName}** with **${response.npoName}**).`
    }
    return "Please sign in to continue."
  }

  switch (status) {
    case 401: return eventSignInMessage(data); break
    case 404: return "The event was not found."; break
  }
}
