import { OrgStatus } from "@fieldday/fielddayportal-model"
import CloseIcon from "@mui/icons-material/Close"
import { Button, Chip, Container, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@mui/material"
import { useEffect } from "react"
import { Helmet } from "react-helmet"
import Header from "../../../components/Global/Header"
import InternalConfigForm from "../../../components/NonProfits/InternalConfigForm"
import BackToWizardLink, { BackToWizardDonationLink } from "../../../components/Wizard/BackToWizardLink"
import { useLoading } from "../../../hooks/useLoading"
import { useReadonlyState } from "../../../hooks/useReadonlyState"
import { NPO } from "../../../models/Npo"
import { useAPI } from "../../../util/useAPI"
import NonProfitDisbursementConfigPreview from "./NonProfitDisbursementConfigPreview"

export default function NonProfitDisbursementConfig() {
  const FieldDayAPI = useAPI()
  const { loadStart, loadEnd } = useLoading()
  const [refreshCounter, setRefreshCounter] = useReadonlyState(0)
  const [npos, setNpos] = useReadonlyState<NPO[] | null>(null)
  function triggerRefresh() {
    setRefreshCounter(refreshCounter + 1)
  }

  useEffect(() => {
    loadStart()
    const abortController = new AbortController()
    FieldDayAPI.listNposNeedingConfig(abortController).then(resp => {
      setNpos(resp.data.nonProfitOrgs)
    }).catch(() => {
      setNpos([])
    }).finally(loadEnd)

    return () => {
      abortController.abort()
    }
  }, [])

  return (<main>
    <Helmet title="Wizard: Nonprofit disbursement config" />
    <Header primaryHeader="Nonprofit config" breadCrumbLink={<BackToWizardLink />} breadCrumbLinks={[<BackToWizardDonationLink key="Donation"/>]} />
    <Container maxWidth="lg" style={{ marginTop: "1em", marginBottom: "2em" }}>
      <NonProfitDisbursementConfigPreview refreshCounter={refreshCounter} triggerRefresh={triggerRefresh} />
    </Container>
    { npos &&
    <Container maxWidth="lg" style={{ marginTop: "1em", marginBottom: "2em" }}>
      <Typography variant="h2">All nonprofits needing details</Typography>
      { npos.length === 0 ? (
        <Typography><em>All caught up!</em></Typography>
      ) : npos.map(npo => (
        <NonProfitDisbursementDetails nonProfitOrg={npo} triggerRefresh={triggerRefresh} key={npo.id} />
      ))
      }
    </Container>
    }
  </main>)
}


function NonProfitDisbursementDetails({ nonProfitOrg, triggerRefresh }: {
  key: string,
  nonProfitOrg?: NPO,
  triggerRefresh: () => void,
}) {
  const [dialogOpen, setDialogOpen] = useReadonlyState(false)
  const closeModal = () => {setDialogOpen(false)}

  return (<>
    {nonProfitOrg && <>
      <Dialog open={dialogOpen} fullWidth maxWidth="xs" onClose={closeModal}>
        <DialogTitle>
          Set confirmed date
          <IconButton
            aria-label="closeDisbursementDetailsDateModal"
            sx={{ position: 'absolute', right: 16, top: 16 }}
            onClick={() => { closeModal()} }
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Container>
            <InternalConfigForm npo={nonProfitOrg} setAllowPrompt={() => {}} afterSubmit={() => {
              triggerRefresh()
              closeModal()
            }} />
          </Container>
        </DialogContent>
      </Dialog>

      <Grid container spacing={2}>
        <Grid item xs={5}>
          <Typography pt={1}>
            {nonProfitOrg?.name}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Chip
            sx={{ mt: 1 }}
            label={nonProfitOrg.status}
            color={nonProfitOrg.status === OrgStatus.Active ? "primary" : "warning"}
            size="small"
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            sx={{ mr: 2 }}
            variant="outlined"
            onClick={(event) => {
              event.stopPropagation()
              event.preventDefault()
              setDialogOpen(true)
            }}
          >Update config</Button>
        </Grid>
      </Grid>
    </>}
  </>)
}
