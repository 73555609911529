import {
  LocationTimeZone,
  MatchableDonationModelI,
  MatchableDonationSchema,
  PlatformDonationSchema,
  getLocationTimeZone,
} from "@fieldday/fielddayportal-model"
import { Box, Button, Container, Divider, FormControl, Grid, Link, TextField, Typography } from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { JSONSchemaType } from "ajv"
import dayjs, { Dayjs } from "dayjs"
import _ from "lodash"
import { useCallback, useEffect } from "react"
import { Helmet } from "react-helmet"
import { useAuth } from "../../hooks/useAuth"
import { useDirty } from "../../hooks/useDirty"
import { AlertSeverity, useLoading } from "../../hooks/useLoading"
import { useReadonlyState } from "../../hooks/useReadonlyState"
import { useScrollToError } from "../../hooks/useScrollToError"
import { NPO } from "../../models/Npo"
import useFormStyles from "../../styles/formStyles"
import ajv from "../../util/ajvConfig"
import { guessedTimeZone, isoDate, today } from "../../util/dateUtil"
import { sortedJsonStringify } from "../../util/objectUtil"
import { dollarCents } from "../../util/stringUtil"
import { useAPI } from "../../util/useAPI"
import { getNestedErrorMessages } from "../../util/validationErrors"
import { CurrencyTextFieldCents } from "../Forms/CurrencyTextField"
import ManagedFieldTripNpoFields, { emptyManagedFieldTripNpo } from "./ManagedFieldTripNpoFields"
import MatchableDonationReceiptUploadForm, { LocalReceiptFile, uploadDonationReceiptFiles } from "./MatchableDonationReceiptUploadForm"

interface props {
  orgUnitId: string,
  header: string,
  onClose: (skipConfirm?: boolean, retryAfter?: number) => void,
  reportDirty: (dirty: boolean) => void,
  reportAmount: (amount: number) => void,
  setSubmitButtonComponent: (buttonComponent: JSX.Element) => void,
  platformDonationOnly?: boolean,
  presetNpo?: NPO,
  presetAmount?: number,
}

export default function PlatformDonationCreateForm(props: props) {
  const { orgUnitId, header, onClose, reportDirty, reportAmount, setSubmitButtonComponent, platformDonationOnly, presetNpo, presetAmount } = props
  const FieldDayAPI = useAPI()
  const formStyles = useFormStyles()
  const { user } = useAuth()

  const stripe = useStripe()
  const elements = useElements()
  const stripeLoaded = !!stripe && !!elements

  const { setAlert, loadStart, loadEnd } = useLoading()

  const [ localFilesArray, setLocalFilesArray ] = useReadonlyState<LocalReceiptFile[]>([])

  const [ errors, setErrors ] = useReadonlyState<Record<string, string | null>>({})
  const scrollToError = useScrollToError()

  const [ platformDonation, setPlatformDonation ] = useReadonlyState(true)

  const npoMission = presetNpo?.description && JSON.parse(presetNpo.description).mission

  const clearDonationErrors = (field: string) => {
    const key = `/${field}`
    if (errors[ key ]) {
      const updatedErrors = { ...errors }
      updatedErrors[ key ] = null
      setErrors(updatedErrors)
    }
  }

  const [ npoForUpdate, setNpoForUpdate ] = useReadonlyState<NPO>(
    presetNpo ?? emptyManagedFieldTripNpo(user)
  )

  const [ matchableDonationForUpdate, setMatchableDonationForUpdate ] = useReadonlyState<MatchableDonationModelI>(
    emptyMatchableDonation(presetAmount, presetNpo))

  useEffect(() => {
    reportAmount(matchableDonationForUpdate.amount ?? 0)
  }, [ matchableDonationForUpdate.amount ])

  const [ npoDirty, setNpoDirty ] = useReadonlyState(false)
  const [ matchableDonationDirty ] = useDirty({
    objForUpdate: matchableDonationForUpdate,
    forDirtyCompare: (matchableDonationForUpdate) => forDirtyCompare(matchableDonationForUpdate, localFilesArray, platformDonation, npoForUpdate),
  })
  const [ allowPropmt, setAllowPrompt ] = useReadonlyState(true)
  const dirtyFields = matchableDonationDirty || npoDirty
  // This is kind of messy, but the parent component is responsible
  // for modal switching and the window.confirm call so it needs
  // to know if this component is dirty. So if dirtyFields changes,
  // report it up to the parent via the reportDirty callback.
  useEffect(() => {
    reportDirty(dirtyFields && allowPropmt)
  }, [ allowPropmt, dirtyFields ])

  const handleAmountChange = (newVal?: number) => {
    const val = !newVal || isNaN(newVal) ? 0 : newVal
    clearDonationErrors("amount")
    const updatedMatchableDonation = Object.assign({}, matchableDonationForUpdate)
    updateMatchableDonation(updatedMatchableDonation, "amount", val)
    setMatchableDonationForUpdate(updatedMatchableDonation)
  }

  const handleDateChange = (newDate: string | null | Dayjs) => {
    clearDonationErrors("dateISO")
    const updatedDonation = Object.assign({}, matchableDonationForUpdate)

    if (newDate) {
      const dateISO = isoDate(newDate)
      updateMatchableDonation(updatedDonation, "dateISO", dateISO)
    } else {
      updateMatchableDonation(updatedDonation, "dateISO", "")
    }

    setMatchableDonationForUpdate(updatedDonation)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedDonation = Object.assign({}, matchableDonationForUpdate)
    const field = event.target.name as keyof MatchableDonationModelI
    clearDonationErrors(field)
    updateMatchableDonation(updatedDonation, field, event.target.value)
    setMatchableDonationForUpdate(updatedDonation)
  }

  function validateMatchableDonation<T>(schema: JSONSchemaType<T>, matchableDonation: MatchableDonationModelI, npo: NPO, extras?: {
    confirmationTokenId?: string,
    timeZone?: LocationTimeZone,
  }) {
    const validateSchema = ajv.compile(schema)
    const request = Object.assign({}, matchableDonation, {
      nonProfitOrgId: npo.id === "" ? undefined : npo.id,
      nonProfitOrgName: npo.id ? undefined : npo.name,
      nonProfitOrgEin: npo.id ? undefined : npo.ein,
      nonProfitOrgCause: npo.id ? undefined : npo.primaryCause,
      dateISO: platformDonation ? today() : matchableDonation.dateISO,
      ...extras,
    })
    validateSchema(request)
    const errors = validateSchema.errors ?? []

    if (!npo.name) {
      errors.push({
        instancePath: "/nonProfitOrg",
        keyword: "required",
        params: { missingProperty: "name" },
        schemaPath: "nonprofitorg.json/required",
      })
    }

    if (!npo.primaryCause) {
      errors.push({
        instancePath: "/nonProfitOrg",
        keyword: "required",
        params: { missingProperty: "cause" },
        schemaPath: "nonprofitorg.json/required",
      })
    }

    if ((matchableDonation.amount ?? 0) < 1) {
      errors.push({
        instancePath: "/amount",
        keyword: "required",
        params: { comparison: "<", limit: 1 },
        schemaPath: "#/properties/amount/required",
      })
    }

    if (!platformDonation) {
      if (localFilesArray.length === 0) {
        errors.push({
          instancePath: "",
          keyword: "required",
          params: { missingProperty: "receiptFiles" },
          schemaPath: "#/properties/receiptFiles/required",
        })
      }

      if (dayjs(matchableDonation.dateISO).isAfter(dayjs())) {
        errors.push({
          instancePath: "/dateISO",
          keyword: "maximum",
          params: { comparison: ">", limit: isoDate(dayjs()) },
          schemaPath: "#/properties/dateISO/maximum",
          message: "Must be in past.",
        })
      }
    }

    if (errors.length > 0) {
      const errs = getNestedErrorMessages(errors)
      setErrors(errs)
      setAlert(AlertSeverity.ERROR, "Please check required fields.")
      scrollToError()
    } else {
      setErrors({})
      return request
    }
  }

  const submitDonation = useCallback(() => {
    const validatedRequest = validateMatchableDonation(MatchableDonationSchema, matchableDonationForUpdate, npoForUpdate)
    if (!validatedRequest) return

    loadStart(true)
    FieldDayAPI.createMatchableDonation(orgUnitId, validatedRequest)
      .then((submitResp) => {
        setAllowPrompt(false)
        reportDirty(false)
        const newMatchableDonation = submitResp?.data.donation
        if (newMatchableDonation?.id && localFilesArray.length > 0) {
          loadStart(true)
          uploadDonationReceiptFiles(newMatchableDonation, localFilesArray, FieldDayAPI)
            .catch(() => {
              setAlert(AlertSeverity.ERROR, "Error uploading receipt file. Edit the donation and upload the receipt again.")
            })
            .finally(() => {
              loadEnd()
              onClose(true)
              setAlert(AlertSeverity.SUCCESS, "Donation report submitted.")
            })
        } else {
          loadEnd()
          onClose(true)
        }
      })
      .catch(err => {
        setAlert(AlertSeverity.ERROR, `${err.response?.data?.message}` || `${err}`)
      })
      .finally(() => {
        loadEnd()
      })
  }, [orgUnitId, forDirtyCompare(matchableDonationForUpdate, localFilesArray, platformDonation, npoForUpdate)])

  const submitStripeDonation = useCallback(() => {
    // Perform some initial validation with the less restrictive MatchableDonation schema.
    const validatedRequest = validateMatchableDonation(MatchableDonationSchema, matchableDonationForUpdate, npoForUpdate)
    if (!validatedRequest) {
      console.error("Failed to validate donation request")
      return
    }

    if (!elements || !stripe) {
      console.error("Missing either elements or stripe", elements, stripe)
      return
    }

    const submitStripe = async () => {
      // Trigger Stripe form validation.
      const { error: submitError } = await elements.submit()
      if (submitError?.message) {
        throw new Error(submitError.message)
      }

      // Create the ConfirmationToken using the details collected by the Payment Element.
      const { error, confirmationToken } = await stripe.createConfirmationToken({
        elements,
        params: {
          payment_method_data: {
            billing_details: {
              name: user?.firstName && user?.lastName ? `${user.firstName} ${user.lastName}` : undefined,
              email: user?.email,
            },
          }
        },
      })

      // Perform the more restrictive validation with the PlatformDonation schema after retrieving a confirmationToken.
      const validatedRequest = validateMatchableDonation(PlatformDonationSchema, matchableDonationForUpdate, npoForUpdate, {
        confirmationTokenId: confirmationToken?.id,
        timeZone: getLocationTimeZone(guessedTimeZone),
      })
      if (!validatedRequest) return

      if (error) {
        // This point is only reached if there's an immediate error when creating the ConfirmationToken.
        if (error?.message) {
          throw new Error(error.message)
        }
      }

      if (!confirmationToken?.id) {
        throw new Error("Failed to initiate payment with payment processor")
      }

      setAllowPrompt(false)
      reportDirty(false)
      try {
        const submitResp = await FieldDayAPI.createPlatformDonationForTeam(orgUnitId, validatedRequest, confirmationToken.id)
        const { status, clientSecret } = submitResp.data.paymentIntent
        if (status === "requires_action") {
          const { error } = await stripe.handleNextAction({ clientSecret: clientSecret })
          if (error) {
            setAlert(AlertSeverity.ERROR, error.message ?? "Error handling payment processor action")
          }
        } else {
          setAlert(AlertSeverity.SUCCESS, "Succesfully processed payment")
        }
      } catch (e) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const err = e as any
        setAlert(AlertSeverity.ERROR, "Your payment was declined" || `${err}`)
      }
    }

    loadStart(true)
    submitStripe()
      .then(() => {
        // Trigger an additional list donations call 5 seconds after
        // the modal is closed.
        onClose(true, 5000)
      })
      .catch((e) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const err = e as any
        setAlert(AlertSeverity.ERROR, err.response?.data?.message || `${err}`)
      })
      .finally(() => {
        loadEnd()
      })
  }, [orgUnitId, forDirtyCompare(matchableDonationForUpdate, localFilesArray, platformDonation, npoForUpdate), stripeLoaded])

  useEffect(() => {
    const submitButtonComponent = platformDonation
      ? <Button
        color="primary"
        variant="contained"
        onClick={() => {
          submitStripeDonation()
        }}
        disabled={!stripeLoaded}
      >
        {`Donate ${dollarCents(matchableDonationForUpdate.amount)}`}
      </Button>
      : <Button color="primary" variant="contained" onClick={submitDonation}>
        {"Submit donation"}
      </Button>

    setSubmitButtonComponent(submitButtonComponent)
  }, [forDirtyCompare(matchableDonationForUpdate, localFilesArray, platformDonation, npoForUpdate),
      submitStripeDonation, submitDonation, stripeLoaded])


  return (
    <>
      <Helmet title={header} />
      <Container maxWidth="md">
        <Box>
          <form className={formStyles.detailsForm}>
            {!platformDonationOnly &&
              <>
                {platformDonation && <Box pb={4}>
                  <Typography display={"inline"}>{presetNpo ? "Make a donation," : "Select a nonprofit to make a donation,"} or</Typography>&nbsp;
                  <Link onClick={() => { setPlatformDonation(false) }}>{"log a donation you've already made."}</Link>
                </Box>}
                {!platformDonation && <Box pb={4}>
                  <Typography display={"inline"}>{"Log a donation you've already made."}</Typography>&nbsp;
                  <Link onClick={() => { setPlatformDonation(true) }}>{"Or make a new donation."}</Link>
                </Box>}
              </>
            }

            <Grid container spacing={3}>
              {!presetNpo && <>
                <Grid item xs={12} sm={5.75}>
                  <Grid container spacing={3}>
                    <ManagedFieldTripNpoFields
                      npoForUpdate={npoForUpdate}
                      setNpoForUpdate={setNpoForUpdate}
                      errors={errors}
                      setErrors={setErrors}
                      setDirty={setNpoDirty}
                      isUpdatedFromInit={false}
                      includeRegion={false}
                      column
                    />

                    <Grid item xs={12}>
                      <FormControl className={formStyles.textInput}>
                        <TextField
                          id="contextMessage"
                          name="contextMessage"
                          label="Memo"
                          value={matchableDonationForUpdate.contextMessage}
                          onChange={handleChange}
                          multiline
                          minRows={3}
                          maxRows={6}
                          error={!!errors[ "/contextMessage" ]}
                          helperText={errors[ "/contextMessage" ] ?? " "}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={.5}>
                  <Divider orientation="vertical" />
                </Grid>
              </>}

              <Grid item xs={12} sm={presetNpo ? 12 : 5.75}>
                <Grid container spacing={3}>
                  {npoMission &&
                    <Grid item xs={12} mt={-1} mb={1}>
                      <Typography variant="subhead3B">Your donation supports {presetNpo.name}&rsquo;s mission:</Typography>
                      <Typography variant="body2" mt={1}>
                        {npoMission}
                      </Typography>
                    </Grid>
                  }
                  <Grid item xs={12} sm={6} mb={-2.6}>
                    <CurrencyTextFieldCents
                      id="amount"
                      name="amount"
                      label="Donation amount"
                      value={matchableDonationForUpdate.amount}
                      onChange={handleAmountChange}
                      error={errors[ "/amount" ]}
                      sx={{ width: "100%" }}
                      minimum={100}
                    />
                  </Grid>

                  {!platformDonation && <>
                    <Grid item xs={12} sm={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Donation date"
                          value={matchableDonationForUpdate.dateISO}
                          onChange={handleDateChange}
                          renderInput={params => <TextField {...params} error={!!errors[ "/dateISO" ]}
                            helperText={errors[ "/dateISO" ] ?? " "}
                          />}
                          maxDate={dayjs()} // force it to be in the past
                        />
                      </LocalizationProvider>
                    </Grid>

                    <Grid item xs={12}>
                      <MatchableDonationReceiptUploadForm
                        buttonText="Choose receipt"
                        setLocalFilesArray={setLocalFilesArray}
                        error={!!errors[ "/receiptFiles" ]}
                        clearErrors={() => clearDonationErrors("receiptFiles")}
                      />
                    </Grid>
                  </>}

                  {platformDonation && // note: minHeight here to keep shape while stripe loads
                    <Grid item xs={12} sx={{ minHeight: "480px" }}>
                      <PaymentElement />
                    </Grid>
                  }
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>
    </>
  )
}


function emptyMatchableDonation(presetAmount?: number, presetNpo?: NPO): MatchableDonationModelI {
  return {
    amount: presetAmount ?? 10000,
    donationUrl: "",
    contextMessage: "",
    dateISO: "",
    nonProfitOrgName: presetNpo?.name,
    nonProfitOrgEin: presetNpo?.ein,
    nonProfitOrgCause: presetNpo?.primaryCause
  }
}

export function updateMatchableDonation<MatchableDonationKey extends keyof MatchableDonationModelI>(
  matchableDonation: MatchableDonationModelI,
  property: MatchableDonationKey,
  value: MatchableDonationModelI[ MatchableDonationKey ]
): void {
  matchableDonation[ property ] = value
}

function forDirtyCompare(donation: MatchableDonationModelI, localFiles: Readonly<LocalReceiptFile[]>, platformDonation: boolean, npo: NPO): string {
  const dirtyFields = _.pick(donation, [
    "nonProfitOrgId",
    "amount",
    "dateISO",
    "status",
    "receiptUrl",
    "donationUrl",
    "contextMessage",
  ])

  const dirtyNpoFields = _.pick(npo, [
    "id",
    "name",
    "ein",
    "primaryCause",
  ])

  return sortedJsonStringify({
    ...dirtyFields,
    npo: sortedJsonStringify(dirtyNpoFields),
    localFiles: localFiles.map(f => f.localUrl).sort(),
    platformDonation: platformDonation,
  })
}
